





























import { defineComponent } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';

import QuestionnaireTable from '../molecules/QuestionnaireTable.vue';
import { PropsQuestionnaires, useQuestionnaires } from './QuestionnairesComposable';

type Props = PropsQuestionnaires;
export default defineComponent({
  name: 'ReportQuestionnaires',
  components: {
    RefreshButton,
    QuestionnaireTable,
    BaseDialogOk,
    BaseDialogConfirm,
  },
  props: {
    groupId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useQuestionnaires(props);
  },
});
