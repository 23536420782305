import BigNumber from 'bignumber.js';

import { LocalDateTime } from '@/base/types';
import { isDefined } from '@/utils/TsUtils';

function parse(x: unknown): number {
  if (!isDefined(x)) return Number.MIN_SAFE_INTEGER;
  if (typeof x === 'number') return x;
  if (typeof x !== 'string' || !x) return Number.MIN_SAFE_INTEGER;
  const f = parseFloat(x.replace(/%$/, ''));
  if (Number.isFinite(f)) return f;
  return Number.MIN_SAFE_INTEGER;
}

export function sortByNumber<T>(a: T, b: T) {
  return parse(a) - parse(b);
}

export function formatYMD(dt?: LocalDateTime) {
  if (!dt) return undefined;
  return dt.format('YYYY/MM/DD');
}

export function formatHour(min?: BigNumber | number) {
  if (!isDefined(min)) return undefined;
  const m = typeof min === 'number' ? min : min.toNumber();
  return Math.round(m / 60);
}

export function formatRate(x?: BigNumber) {
  if (!x) return undefined;
  return `${Math.round(x.toNumber())}%`;
}

export function formatPassFail(record: { isPassed?: boolean } | {}) {
  if ('isPassed' in record) {
    if (!isDefined(record.isPassed)) return 'unknown';
    return record.isPassed ? 'passed' : 'notPassed';
  }
  return 'unknown';
}
