

















































import { defineComponent, PropType } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsUserUsageTraineeCourseTable,
  UserUsageTraineeAverage,
  UserUsageTraineeCourseItem,
  useUserUsageTraineeCourseTable,
} from './UserUsageTraineeCourseTableComposable';

type Props = PropsUserUsageTraineeCourseTable;
export default defineComponent({
  name: 'ReportUserUsageTraineeCourseTable',
  components: { BaseButton, BaseTable },
  inheritAttrs: false,
  props: {
    groupId: { type: String, required: true },
    courseRecords: { type: Array as PropType<UserUsageTraineeCourseItem[]>, default: () => [] },
    courseAverage: { type: Object as PropType<UserUsageTraineeAverage>, required: true },
  },
  setup(props: Props) {
    return useUserUsageTraineeCourseTable(props);
  },
});
