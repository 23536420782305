



import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsUserUsageGroupExamTable,
  UserUsageGroupExamItem,
  useUserUsageGroupExamTable,
} from './UserUsageGroupExamTableComposable';

type Props = PropsUserUsageGroupExamTable;

export default defineComponent({
  name: 'ReportUserUsageGroupExamTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    exams: { type: Array as PropType<UserUsageGroupExamItem[]>, default: () => [] },
  },
  setup(props: Props) {
    return useUserUsageGroupExamTable(props);
  },
});
