import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { MyExamResult } from '@/base/domains';
import { isDefined } from '@/utils/TsUtils';

export type PropsExamResultCard = MyExamResult & {
  contentName: string;
  courseName: string;
  indexInCourse: number;
  userName: string;
  timeLimit: number | string;
  times?: number;
  returnQuery?: Record<string, unknown>;
};

export function useExamResultCard(props: PropsExamResultCard) {
  const msgs = useMessages({ prefix: 'report.molecules.examResultCard' });

  function calculate(v?: number) {
    if (!isDefined(v) || !isDefined(props.problemCount)) return undefined;
    if (!v || !props.problemCount) return 0;
    return Math.round((v / props.problemCount) * 1000) / 10;
  }

  const examName = computed(
    () =>
      msgs.of('examName', {
        courseName: props.courseName,
        contentName: props.contentName,
        times: props.times ?? '-',
      }).value
  );
  const scorePer = computed(() => {
    if (props.visibilityLevel === 'invisible_to_user') return undefined;
    return calculate(props.score);
  });
  const passingStandardPer = computed(() => {
    if (props.visibilityLevel === 'invisible_to_user') return undefined;
    if ('passingStandard' in props) return calculate(props.passingStandard);
    return undefined;
  });
  const startEnd = computed(() =>
    [props.start.format('YY/MM/DD HH:mm'), props.end.format('HH:mm')].join(' - ')
  );

  const toGroupUserExamResult = computed(() => {
    if (props.visibilityLevel !== 'details') return undefined;
    return {
      name: 'groupUserExamResult',
      params: { groupId: props.groupId, examId: props.id },
      query: props.returnQuery,
    };
  });

  return {
    examName,
    scorePer,
    passingStandardPer,
    startEnd,
    toGroupUserExamResult,
    labelPassed: msgs.of('passed'),
    labelNotPassed: msgs.of('notPassed'),
    labelResult: msgs.of('result'),
    labelScoreInvisibleToUser: msgs.of('scoreInvisibleToUser'),
    labelDetails: msgs.of('details'),
    labelDetailsInvisibleToUser: msgs.of('detailsInvisibleToUser'),
  };
}
