import { computed, readonly, ref } from '@vue/composition-api';

import { useProblemController } from '@/base/app/utils/ProblemUtils';
import { ExamContent, MyExamResult } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { useGetMyExamResultDetails } from '@/report/usecases';
import { injectionKeyOf } from '@/utils/VueUtils';

export function useUserExamResultStore() {
  const userExamAndResult = ref<{ exam: ExamContent; result: MyExamResult }>();
  const loading = ref(false);

  const problemController = useProblemController({
    content: computed(() => {
      if (!userExamAndResult.value) return undefined;
      const { body } = userExamAndResult.value.exam;
      return { type: 'exam' as const, body };
    }),
  });

  const getMyExamResult = useGetMyExamResultDetails();
  async function fetch(id: string) {
    loading.value = true;
    const res = await getMyExamResult.execute({ id });
    loading.value = false;
    if (
      isFailed(res) ||
      !res.exam ||
      res.exam.type !== 'exam' ||
      !res.examResult ||
      res.examResult.visibilityLevel !== 'details'
    ) {
      userExamAndResult.value = undefined;
      return;
    }
    userExamAndResult.value = { exam: res.exam, result: res.examResult };
    problemController.init({
      converter: (x, _, f) => {
        if (!userExamAndResult.value) return x;
        const { result } = userExamAndResult.value;
        if (result.visibilityLevel !== 'details') return x;
        const answer = result.answers.find((item) => item.index === x.index);
        // scoredChoiceNosを選択結果に置き換え
        const scoredChoiceNos = answer ? f(answer.values as number[]) : [];
        // passedを正否(correct)に置き換え
        const passed = answer?.correct ?? false;
        return { ...x, scoredChoiceNos, passed };
      },
    });
  }

  const notFound = computed(() => !userExamAndResult.value);
  const exam = computed(() => userExamAndResult.value?.exam);
  const result = computed(() => userExamAndResult.value?.result);

  return {
    loading: readonly(loading),
    notFound,
    exam,
    result,
    details: problemController.problems,
    fetch,
  };
}

export type UserExamResultStore = ReturnType<typeof useUserExamResultStore>;

export const UserExamResultStoreKey = injectionKeyOf<UserExamResultStore>({
  boundedContext: 'report',
  type: 'store',
  name: 'UserExamResultStore',
});
