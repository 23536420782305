import { computed, ref } from '@vue/composition-api';

import { Questionnaire } from '@/base/domains';
import { GetQuestionnaireRequest, isFailed, useGetQuestionnaire } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

export function useQuestionnaireStore() {
  const questionnaire = ref<Questionnaire>();
  const loading = ref(false);

  const getQuestionnaire = useGetQuestionnaire();
  async function fetch(req: GetQuestionnaireRequest) {
    loading.value = true;
    const res = await getQuestionnaire.execute(req);
    loading.value = false;
    if (isFailed(res)) {
      questionnaire.value = undefined;
      return;
    }
    questionnaire.value = res.questionnaire;
  }

  async function refresh() {
    assertIsDefined(questionnaire.value, 'questionnaire');
    await fetch({ id: questionnaire.value.id });
  }

  const notFound = computed(() => !questionnaire.value);

  return {
    questionnaire: readonly(questionnaire),
    loading: readonly(loading),
    notFound,
    fetch,
    refresh,
  };
}

export type QuestionnaireStore = ReturnType<typeof useQuestionnaireStore>;

export const QuestionnaireStoreKey = injectionKeyOf<QuestionnaireStore>({
  boundedContext: 'report',
  type: 'store',
  name: 'QuestionnaireStore',
});
