



































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { ExamResultVisibilityLevel, GroupExam } from '@/base/domains';

import {
  GetGroupReportSearchConditionsContent,
  GetGroupReportSearchConditionsUser,
} from '../../../usecases';
import {
  ExamResultUserSearchFieldsCondition,
  PropsExamResultUserSearchFields,
  useExamResultUserSearchFields,
} from './ExamResultUserSearchFieldsComposable';

type Props = PropsExamResultUserSearchFields;

export default defineComponent({
  name: 'ReportExamResultUserSearchFields',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<ExamResultUserSearchFieldsCondition>, default: undefined },
    contents: {
      type: Array as PropType<GetGroupReportSearchConditionsContent[]>,
      default: () => [],
    },
    users: { type: Array as PropType<GetGroupReportSearchConditionsUser[]>, default: () => [] },
    groupExams: { type: Array as PropType<GroupExam[]>, default: () => [] },
    fixVisibilityLevel: { type: String as PropType<ExamResultVisibilityLevel>, default: undefined },
    disabledUser: { type: Boolean, default: false },
    disabledTimes: { type: Boolean, default: false },
    disabledProblems: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  emits: ['change', 'refresh'],
  setup(props: Props, { emit }: SetupContext) {
    return useExamResultUserSearchFields(props, emit);
  },
});
