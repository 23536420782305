












import { defineComponent, PropType } from '@vue/composition-api';

import DateTimeNow from '@/base/app/components/molecules/DateTimeNow.vue';
import { LocalDateTime } from '@/base/types';

import { PropsUserUsageDetail, useUserUsageDetail } from './UserUsageDetailComposable';

type Props = PropsUserUsageDetail;

export default defineComponent({
  name: 'ReportUserUsageDetail',
  components: { DateTimeNow },
  props: {
    startOfUse: { type: Object as PropType<LocalDateTime>, default: undefined },
    recentUsedAt: { type: Object as PropType<LocalDateTime>, default: undefined },
    usageTime: { type: Number, default: 0 },
    courseCount: { type: Number, default: 0 },
    contentCount: { type: Number, default: 0 },
    completedCourseCount: { type: Number, default: 0 },
    completedContentCount: { type: Number, default: 0 },
    commentCount: { type: Number, default: 0 },
    completedExamCount: { type: Number, default: 0 },
  },
  setup(props: Props) {
    return useUserUsageDetail(props);
  },
});
