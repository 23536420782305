





























import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';

import GroupUserExamResults from '../components/organisms/GroupUserExamResults.vue';
import GroupUserExamResultsMobile from '../components/organisms/GroupUserExamResultsMobile.vue';

export default defineComponent({
  name: 'ReportGroupUserExamResultHistoryPage',
  components: { MyApp, GroupUserExamResults, GroupUserExamResultsMobile },
  props: {
    id: { type: String, required: true },
    contentId: { type: String, default: undefined },
    times: { type: String, default: undefined },
  },
  setup() {
    const { user } = useGlobalStore();
    return { user };
  },
});
