import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';

import { UserCourseProgresses } from '../../../domains';
import { formatRate, sortByNumber } from '../../utils/TableUtils';

export type UserUsageGroupCourseItem = UserCourseProgresses;

export type PropsUserUsageGroupCourseTable = {
  courses: UserUsageGroupCourseItem[];
};

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'contentCount', text: '', align: 'center', width: 100 },
  { value: 'notBegunContentCount', text: '', align: 'center', width: 100 },
  { value: 'inProgressContentCount', text: '', align: 'center', width: 100 },
  { value: 'completedContentCount', text: '', align: 'center', width: 100 },
  { value: 'progressRate', text: '', align: 'center', width: 100, sort: sortByNumber },
];

export function useUserUsageGroupCourseTable(props: PropsUserUsageGroupCourseTable) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageGroupCourseTable' });

  const headers = HEADERS.map((header) => ({
    ...header,
    text: msgs.of(header.value).value,
  }));

  const items = computed(() => {
    return props.courses
      .map((c) => ({
        ...c,
        progressRate: formatRate(c.progressRate),
      }))
      .sort((a, b) => {
        if (a.name === b.name) return a.id < b.id ? -1 : 1;
        return a.name < b.name ? -1 : 1;
      });
  });

  return {
    headers,
    items,
  };
}

export type UserUsageGroupCourseTable = ReturnType<typeof useUserUsageGroupCourseTable>;
