import { computed } from '@vue/composition-api';
import moment from 'moment';

import { useMessages } from '@/base/app';
import { LocalDateTime } from '@/base/types';

type DetailRow =
  | {
      key: string;
      type: 'datetime';
      value: LocalDateTime;
    }
  | {
      key: string;
      type: 'text';
      value: string;
    };

export type PropsUserUsageDetail = {
  startOfUse?: LocalDateTime;
  recentUsedAt?: LocalDateTime;
  usageTime: number;
  courseCount: number;
  contentCount: number;
  completedCourseCount: number;
  completedContentCount: number;
  commentCount: number;
  completedExamCount: number;
};

export function useUserUsageDetail(props: PropsUserUsageDetail) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageDetail' });

  function toValue(
    dt?: LocalDateTime
  ): { type: 'datetime'; value: LocalDateTime } | { type: 'text'; value: string } {
    if (!dt) return { type: 'text', value: '---' };
    if (moment().diff(dt, 'days') <= 7) return { type: 'datetime', value: dt };
    return { type: 'text', value: dt.format('ll') };
  }

  const details = computed(() => {
    const ret: DetailRow[] = [];
    // 利用開始日
    ret.push({ key: 'startedToUse', ...toValue(props.startOfUse) });
    // 直近利用
    ret.push({ key: 'recentUse', ...toValue(props.recentUsedAt) });
    // 利用時間
    ret.push({
      key: 'usedTime',
      type: 'text',
      value: msgs.of('totalTime', { totalTime: props.usageTime }).value,
    });
    // コース
    ret.push({
      key: 'course',
      type: 'text',
      value: msgs.of('finishedCourses', {
        finishedCourses: props.completedCourseCount,
        totalCourses: props.courseCount,
      }).value,
    });
    // コンテンツ
    ret.push({
      key: 'content',
      type: 'text',
      value: msgs.of('finishedContents', {
        finishedContents: props.completedContentCount,
        totalContents: props.contentCount,
      }).value,
    });
    // コメント
    ret.push({
      key: 'comment',
      type: 'text',
      value: msgs.of('createdComments', {
        createdComments: props.commentCount,
      }).value,
    });
    // テスト
    ret.push({
      key: 'exam',
      type: 'text',
      value: msgs.of('tookExams', {
        tookExams: props.completedExamCount,
      }).value,
    });
    return ret.map((item) => ({ ...item, title: msgs.of(item.key).value }));
  });

  return { details };
}
