import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { ProblemResultsViewClickAnchorPayload } from '@/base/app/components/molecules/ProblemResultsViewComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject } from '@/utils/VueUtils';

import { UserExamResultStoreKey } from '../../stores';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: ProblemResultsViewClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export type PropsGroupUserExamResult = {
  id: string;
};

export function useGroupUserExamResult(props: PropsGroupUserExamResult) {
  const { result, details, fetch } = requiredInject(UserExamResultStoreKey);

  function init() {
    fetch(props.id);
  }
  onMounted(init);
  watch(() => props.id, init);

  const style = computed(() => {
    const h = result.value?.visibilityLevel === 'details' ? undefined : '75vh';
    return { minHeight: h };
  });

  return {
    details,
    result,
    style,
    ...useAnchorDialog(),
  };
}
