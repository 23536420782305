



















































import { defineComponent } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';

import ExamResultContentSearchFields from '../molecules/ExamResultContentSearchFields.vue';
import ExamResultTable from '../molecules/ExamResultTable.vue';
import ExamResultUserSearchFields from '../molecules/ExamResultUserSearchFields.vue';
import { PropsGroupExamResults, useGroupExamResults } from './GroupExamResultsComposable';

type Props = PropsGroupExamResults;

export default defineComponent({
  name: 'ReportGroupExamResults',
  components: {
    ExamResultContentSearchFields,
    ExamResultUserSearchFields,
    RefreshButton,
    ExamResultTable,
  },
  props: {
    groupId: { type: String, required: true },
    contentId: { type: String, default: undefined },
    times: { type: String, default: undefined },
    topHeight: { type: Number, default: 64 },
  },
  setup(props: Props) {
    return useGroupExamResults(props);
  },
});
