import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useChartConfig } from '@/base/app/utils/ChartUtils';

export type PropsUserDataAndAverageBarChart = {
  title: string;
  userData: number;
  usersAverage: number;
  labelData: string;
  unit: string;
  labelSize: number;
};

export function useUserDataAndAverageBarChart(props: PropsUserDataAndAverageBarChart) {
  const msgs = useMessages({ prefix: 'report.molecules.userDataAndAverageBarChart' });
  const { primaryColors, backgroundGrey, axisColor, fontColor, fontStyle } = useChartConfig();

  const dataLabelPlugin = {
    id: 'dataLabel',
    afterDatasetsDraw(chart) {
      const { ctx } = chart;
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.forEach((element, n) => {
            const dataString = dataset.data[n].toString();
            ctx.fillStyle = fontColor.value;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'top';
            ctx.font = `${props.labelSize}px ${fontStyle}`;
            const padding = 10;
            const position = element.tooltipPosition();
            ctx.fillText(dataString, position.x, position.y - props.labelSize / 2 - padding);
          });
        }
      });
    },
  };

  const plugins = computed(() => [dataLabelPlugin]);

  function calculateYAxesMax(userData: number, usersAverage: number) {
    if (userData === 0 && usersAverage === 0) return 10;
    return userData >= usersAverage
      ? Math.ceil((userData * 1.2) / 10) * 10
      : Math.ceil((usersAverage * 1.2) / 10) * 10;
  }

  function calculateStepSize(userData: number, usersAverage: number) {
    if (userData === 0 && usersAverage === 0) return 5;
    return userData >= usersAverage ? Math.round(userData / 2) : Math.round(usersAverage / 2);
  }

  const options = computed(() => ({
    scales: {
      xAxes: {
        grid: {
          display: false,
          borderColor: axisColor.value,
        },
        ticks: {
          color: fontColor.value,
        },
      },
      yAxes: {
        grid: {
          display: false,
          borderColor: axisColor.value,
        },
        min: 0,
        max: calculateYAxesMax(props.userData, props.usersAverage),
        ticks: {
          color: fontColor.value,
          stepSize: calculateStepSize(props.userData, props.usersAverage),
          callback(value) {
            return `${value}${props.unit}`;
          },
          font: {
            size: `${props.labelSize}px`,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }));

  const data = computed(() => {
    const colors = primaryColors.value;
    return {
      labels: [msgs.of('average').value, props.labelData],
      datasets: [
        {
          data: [Math.round(props.usersAverage), props.userData],
          backgroundColor: [backgroundGrey.value, ...colors],
          hoverOffset: 4,
        },
      ],
    };
  });

  const style = computed(() => ({
    width: '250px',
    height: '250px',
    'max-height': '100%',
    'max-width': '100%',
  }));

  return { options, data, style, plugins };
}

export type UserDataAndAverageBarChart = ReturnType<typeof useUserDataAndAverageBarChart>;
