import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  QuestionnaireConfirmFinishDialog,
  QuestionnaireConfirmFinishDialogSubmitPayload,
} from '@/base/app/components/molecules/QuestionnaireConfirmFinishDialogComposable';
import {
  QuestionnaireProgressClickAnchorPayload,
  QuestionnaireProgressRespondent,
} from '@/base/app/components/molecules/QuestionnaireProgressComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { useGlobalStore } from '@/base/app/store';
import { GroupId, QuestionnaireId } from '@/base/domains';
import { isFailed, useFinishQuestionnaire, useRemoveQuestionnaire } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRouter } from '@/utils/VueUtils';

import { QuestionnaireStoreKey } from '../../stores';

export type PropsQuestionnaireResult = {
  id: QuestionnaireId;
  groupId: GroupId;
};

export type QuestionnaireResultClickAnchorPayload = QuestionnaireProgressClickAnchorPayload;

function useQuestionnaireConfirmFinishDialog() {
  const confirmFinishDialog = ref<QuestionnaireConfirmFinishDialog>();
  function open() {
    assertIsDefined(confirmFinishDialog.value);
    confirmFinishDialog.value.open();
  }
  return { confirmFinishDialog, open };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: QuestionnaireProgressClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export function useQuestionnaireResult(props: PropsQuestionnaireResult) {
  const msgs = useMessages({ prefix: 'report.organisms.questionnaireResult' });
  const router = useRouter();

  const { confirmFinishDialog, open: confirmFinish } = useQuestionnaireConfirmFinishDialog();
  const store = requiredInject(QuestionnaireStoreKey);
  const { findUser } = useGlobalStore();

  const loading = ref(false);

  function fetch() {
    store.fetch({ id: props.id });
  }
  onMounted(fetch);
  watch(() => props.id, fetch);

  function moveToPage() {
    router.replace({
      name: 'trainerGroupQuestionnaires',
      params: { id: props.groupId },
    });
  }

  const finished = computed(() => store.questionnaire.value?.status === 'finished');

  const respondent = computed<QuestionnaireProgressRespondent[]>(() => {
    if (!store.questionnaire.value) return [];
    const { userIds, respondent: res } = store.questionnaire.value;
    return userIds.map((id) => ({
      id,
      name: findUser(id)?.name ?? msgs.of('unknown', { id }).value,
      selectedIndex: res.find((item) => item.userId === id)?.selectedIndex,
    }));
  });

  const finishQuestionnaire = useFinishQuestionnaire();
  const removeQuestionnaire = useRemoveQuestionnaire();
  async function finish(payload: QuestionnaireConfirmFinishDialogSubmitPayload) {
    loading.value = true;
    const res = await finishQuestionnaire.execute({ id: props.id });
    if (isFailed(res)) {
      payload.done();
      loading.value = false;
      return;
    }
    if (!payload.value) {
      const removeRes = await removeQuestionnaire.execute({ id: props.id });
      if (isFailed(removeRes)) {
        payload.done();
        loading.value = false;
        return;
      }
    }
    payload.done();
    loading.value = false;
    moveToPage();
  }

  return {
    questionnaire: store.questionnaire,
    finished,
    respondent,
    loading,
    confirmFinishDialog,
    labelFinish: msgs.of('finish'),
    confirmFinish,
    finish,
    ...useAnchorDialog(),
  };
}
