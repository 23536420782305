import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { ContentId, GroupId } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined } from '@/utils/TsUtils';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { GroupExamCorrections } from '../../../domains';
import { GetGroupExamCorrectionsRequest, useGetGroupExamCorrections } from '../../../usecases';
import { formatRate } from '../../utils/TableUtils';

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function info(msg: string, ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg, ok);
  }
  return { dialogFullScreen, dialog, opened, info };
}

export function useUserUsageTraineeExamDialog() {
  const msgs = useMessages({ prefix: 'report.organisms.userUsageTraineeExamDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, info } = useDialogFullScreen();

  const target = ref<GetGroupExamCorrectionsRequest>();
  const report = ref<GroupExamCorrections>();
  const loading = ref(false);

  const subtitle = computed(() => {
    if (!report.value) return undefined;
    const { courseName, contentName, passingStandardRate } = report.value;
    const ret = [
      courseName,
      '>',
      contentName,
      msgs.of('examTimes', { times: target.value?.times ?? '-' }).value,
    ];
    const rate = formatRate(passingStandardRate);
    if (isDefined(rate)) return [...ret, msgs.of('passingStandard', { rate }).value].join(' ');
    return ret.join(' ');
  });
  const headerKeys = computed(() => {
    if (!report.value) return [];
    const keys = ['userName', 'correctAnswerRate'];
    if (isDefined(report.value.passingStandardRate)) return [...keys, 'passFail'];
    return keys;
  });
  const items = computed(() => report.value?.userCorrectAnswerRateList);

  const getReports = useGetGroupExamCorrections();
  async function fetch() {
    assertIsDefined(target.value, 'target');
    loading.value = true;
    const res = await getReports.execute(target.value);
    loading.value = false;
    if (isFailed(res)) return false;
    report.value = res.report;
    return true;
  }

  function close() {
    dialog.value = { display: false };
    report.value = undefined;
    const to = clearDialogQuery(route, '#userUsageTraineeExamTable');
    if (to) router.replace(to);
  }

  async function open(groupId: GroupId, contentId: ContentId, times: number) {
    dialog.value = { display: true };
    target.value = { groupId, contentId, times };
    const ret = await fetch();
    if (!ret) info(msgs.of('noData').value, close);
  }

  return {
    dialogFullScreen,
    dialog,
    loading,
    items,
    headerKeys,
    title: msgs.of('userUsageCorrectAnswerRate'),
    subtitle,
    close,
    open,
    opened,
  };
}

export type UserUsageTraineeExamDialog = ReturnType<typeof useUserUsageTraineeExamDialog>;
