import { computed, ComputedRef, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { Questionnaire } from '@/base/domains';
import { isSucceeded, useGetQuestionnaires, useRemoveQuestionnaire } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  QuestionnaireTable,
  QuestionnaireTableActionPayload,
  QuestionnaireTableItem,
} from '../molecules/QuestionnaireTableComposable';

export type PropsQuestionnaires = {
  groupId: string;
};

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function confirm(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, confirm };
}

function useTable() {
  const table = ref<QuestionnaireTable>();
  function clear() {
    if (table.value) table.value.clear();
  }
  return { table, clear };
}

const MENUS = [{ value: 'create' }, { value: 'remove', required: true }];

export function useQuestionnaires(props: PropsQuestionnaires) {
  const msgs = useMessages({ prefix: 'report.organisms.questionnaires' });
  const { user: me, findUser } = useGlobalStore();
  const { errorDialog, error } = useErrorDialog();
  const { confirmDialog, confirm } = useConfirmDialog();
  const { table, clear: clearTableSelection } = useTable();
  const router = useRouter();
  const route = useRoute();
  const { getQuery, moveTo: moveToQuestionnaire } = useDialogQuery(DialogName.BASE_QUESTIONNAIRE);

  const questionnaires = ref<Questionnaire[]>();

  const loading = ref(false);
  const own = ref(false);

  const getQuestionnaires = useGetQuestionnaires();
  async function fetch() {
    loading.value = true;
    const res = await getQuestionnaires.execute({ groupId: props.groupId });
    loading.value = false;
    if (isSucceeded(res)) {
      questionnaires.value = res.questionnaires;
      return;
    }
    questionnaires.value = [];
  }
  onMounted(fetch);
  watch(() => props.groupId, fetch);

  function done() {
    clearTableSelection();
    fetch();
  }

  const openedDialog = computed(() => !!getQuery());

  watch(openedDialog, (newVal) => {
    if (!newVal) {
      done();
    }
  });

  const removeQuestionnaire = useRemoveQuestionnaire();
  async function remove(ids: string[]) {
    await Promise.all(ids.map((id) => removeQuestionnaire.execute({ id })));
  }

  async function action({ event, selected }: QuestionnaireTableActionPayload) {
    switch (event) {
      case 'show': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        router.push({
          name: 'trainerGroupQuestionnaire',
          params: { id: props.groupId, questionnaireId: first.id },
          query: { ...toQuery(route) },
        });
        break;
      }
      case 'remove': {
        if (selected.length === 0) return;
        if (selected.some((item) => item.status === 'active')) {
          error([msgs.of('errorRemoveActiveQuestionnaire').value]);
          return;
        }
        confirm(msgs.of('confirmRemove').value, async () => {
          loading.value = true;
          await remove(selected.map((item) => item.id));
          done();
        });
        break;
      }
      case 'create':
        moveToQuestionnaire({ groupId: props.groupId });
        break;
      default:
    }
  }

  const items: ComputedRef<QuestionnaireTableItem[]> = computed(
    () =>
      questionnaires.value
        ?.filter((item) => !own.value || item.createdBy === me.value?.id)
        .map((item) => ({
          ...item,
          createdByName:
            findUser(item.createdBy)?.name ?? msgs.of('unknown', { id: item.createdBy }).value,
        })) ?? []
  );

  const menus = computed(() => {
    return MENUS.map((m) => ({ ...m, label: msgs.of(m.value).value }));
  });

  return {
    errorDialog,
    confirmDialog,
    table,
    items,
    menus,
    loading,
    own,
    labelOwn: msgs.of('own'),
    refresh: fetch,
    action,
  };
}
