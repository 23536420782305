import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { GroupId, GroupName, GroupRole, UserId, UserName } from '@/base/domains';
import {
  GetTrainerUserReportConditionsGroup,
  GetTrainerUserReportConditionsUser,
} from '@/report/usecases';
import { assertIsDefined } from '@/utils/Asserts';

type UserType = GroupRole | 'non-active';

type ConditionGroupUser = Omit<GetTrainerUserReportConditionsUser, 'name'> & {
  name: string;
};

export type UserUsageSearchFieldsConditionGroup = Omit<
  GetTrainerUserReportConditionsGroup,
  'users'
> & {
  users: ConditionGroupUser[];
};

export type UserUsageSearchFieldsValue = {
  groupId: GroupId;
  groupName: GroupName;
  userId: UserId;
  userName: UserName;
};

export type PropsUserUsageSearchFields = {
  value?: UserUsageSearchFieldsValue;
  groups: UserUsageSearchFieldsConditionGroup[];
};

export function useUserUsageSearchFields(
  props: PropsUserUsageSearchFields,
  emit: (name: string, args: UserUsageSearchFieldsValue) => void
) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageSearchFields' });

  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const groupId = ref<GroupId>();
  const userType = ref<UserType>();
  const userId = ref<UserId>();

  function init() {
    const [first] = props.groups;
    groupId.value = first?.id;
    userType.value = 'trainee';
    userId.value = undefined;
  }
  onMounted(init);
  watch(() => props.groups, init);

  watch(
    () => props.value,
    (newVal) => {
      if (!newVal) return;
      groupId.value = newVal.groupId;
      if (newVal.userId === 'trainees') {
        userType.value = 'trainee';
        userId.value = newVal.userId;
      } else {
        const group = props.groups.find((g) => g.id === newVal.groupId);
        if (group) {
          const user = group.users.find((u) => u.id === newVal.userId);
          if (user) {
            userType.value = user.groupRole;
            userId.value = user.id;
          }
        }
      }
    }
  );

  const groupUsers = computed(() => {
    if (!groupId.value || !userType.value) return [];
    const group = props.groups.find((item) => item.id === groupId.value);
    if (!group) return [];
    const trainees: ConditionGroupUser = {
      id: 'trainees',
      name: msgs.of('trainees').value,
      active: true,
      groupRole: 'trainee',
    };
    return [trainees, ...group.users].filter((u) => {
      if (userType.value === 'non-active') return !u.active;
      return u.active && userType.value === u.groupRole;
    });
  });

  function clearUserId() {
    userId.value = undefined;
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    assertIsDefined(groupId.value, 'groupId');
    assertIsDefined(userId.value, 'userId');
    const group = props.groups.find((g) => g.id === groupId.value);
    assertIsDefined(group, 'group');
    if (userId.value === 'trainees') {
      emit('change', {
        groupId: groupId.value,
        groupName: group.name,
        userId: 'trainees',
        userName: msgs.of('trainees').value,
      });
    }
    const user = groupUsers.value.find((u) => u.id === userId.value);
    assertIsDefined(user, 'user');
    emit('change', {
      groupId: groupId.value,
      groupName: group.name,
      userId: userId.value,
      userName: user.name,
    });
  }

  return {
    observer,
    groupId,
    userType,
    userId,
    groupUsers,
    labelGroup: msgs.of('group'),
    labelUserType: msgs.of('type'),
    labelUser: msgs.of('user'),
    labelSearch: msgs.of('search'),
    userTypes: msgs.listOf('types'),
    clearUserId,
    submit,
  };
}
