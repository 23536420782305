import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupExam } from '@/base/domains';

import {
  GetGroupReportSearchConditionsContent,
  GetGroupReportSearchConditionsUser,
} from '../../../usecases';

export type ExamResultContentSearchFieldsCondition = {
  contentId?: string;
  times?: number;
  problem?: number;
  userIds: string[];
};

export type PropsExamResultContentSearchFields = {
  value?: ExamResultContentSearchFieldsCondition;
  contents: GetGroupReportSearchConditionsContent[];
  users: GetGroupReportSearchConditionsUser[];
  groupExams: GroupExam[];
};

export function useExamResultContentSearchFields(
  props: PropsExamResultContentSearchFields,
  emit: (name: string, args?: unknown) => void
) {
  const msgs = useMessages({ prefix: 'report.molecules.examResultContentSearchFields' });
  const input = ref<ExamResultContentSearchFieldsCondition>({ userIds: [] });

  function init() {
    input.value = {
      contentId: '',
      times: Number.NaN,
      problem: Number.NaN,
      userIds: [],
      ...props.value,
    };
  }
  onMounted(init);
  watch(() => props.value, init);

  function change(payload: Record<string, string | number | null>) {
    if ('contentId' in payload && !payload.contentId) {
      input.value.contentId = '';
      input.value.times = Number.NaN;
      input.value.problem = Number.NaN;
      input.value.userIds = [];
    }
    emit('change', { ...input.value, ...payload });
    if ('contentId' in payload) emit('refresh');
  }

  const filters = computed(() => {
    const contents = props.contents
      .map((item) => ({
        ...item,
        text: msgs.of('name', { courseName: item.courseName, contentName: item.name }).value,
      }))
      .sort((a, b) => {
        if (a.courseName === b.courseName) return a.indexInCourse - b.indexInCourse;
        return a.courseName < b.courseName ? -1 : 1;
      });
    let exams: { value: number; text: string }[] = [];
    let problems: { value: number; text: string }[] = [];
    if (input.value.contentId) {
      exams = props.groupExams
        .filter((item) => item.content.id === input.value.contentId)
        .map((item) => ({
          value: item.times,
          text: msgs.of('examTimes', { times: item.times }).value,
        }))
        .sort((a, b) => a.value - b.value);
      const counts = props.groupExams
        .filter(
          (item) =>
            item.content.id === input.value.contentId &&
            (!input.value.times || item.times === input.value.times)
        )
        .map((item) => item.content.problems.length);
      if (counts.length > 0) {
        problems = [...Array(Math.max(...counts))].map((_, i) => ({
          value: i,
          text: msgs.of('problemNo', { no: i + 1 }).value,
        }));
      }
    }
    const users = props.users
      .map((item) => {
        if (!item.name) {
          return {
            ...item,
            text: msgs.of('unknownUser', { id: item.id }).value,
          };
        }
        return {
          ...item,
          text: item.removed ? msgs.of('removedUser', { name: item.name }).value : item.name,
        };
      })
      .sort((a, b) => {
        if (a.name === b.name) return a.id < b.id ? -1 : 1;
        if (a.name === undefined) return 1;
        if (b.name === undefined) return -1;
        if (a.removed === b.removed) return a.name < b.name ? -1 : 1;
        return a.removed < b.removed ? -1 : 1;
      });
    return { contents, exams, problems, users };
  });
  const labelSelected = computed(
    () => msgs.of('selected', { size: input.value.userIds.length }).value
  );

  return {
    input,
    filters,
    placeholderCourseExam: msgs.of('courseExam'),
    placeholderTimes: msgs.of('times'),
    placeholderUserName: msgs.of('userName'),
    placeholderQuestionNo: msgs.of('questionNo'),
    labelSelected,
    change,
  };
}
