import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { GroupExam, MyExamResult } from '@/base/domains';
import { isSucceeded } from '@/base/usecases';
import { useRoute } from '@/utils/VueUtils';

import {
  GetGroupReportSearchConditionsContent,
  GetGroupReportSearchConditionsUser,
  useGetGroupReportSearchConditions,
  useGetMyExamResults,
} from '../../../usecases';
import { ExamResultUserSearchFieldsCondition } from '../molecules/ExamResultUserSearchFieldsComposable';

export type PropsGroupUserExamResults = {
  groupId: string;
  userId: string;
  contentId?: string;
  times?: string;
  topHeight: number;
};

export function useGroupUserExamResults(props: PropsGroupUserExamResults, isMobile = false) {
  const msgs = useMessages({ prefix: 'report.organisms.groupUserExamResults' });
  const route = useRoute();

  const results = ref<MyExamResult[]>([]);
  const loading = ref(false);
  const condition = ref<ExamResultUserSearchFieldsCondition>({});
  const users = ref<GetGroupReportSearchConditionsUser[]>([]);
  const contents = ref<GetGroupReportSearchConditionsContent[]>([]);
  const groupExams = ref<GroupExam[]>([]);
  const returnQuery = computed(() => toQuery(route, { contentId: condition.value.contentId }));

  const getConditions = useGetGroupReportSearchConditions();
  async function fetchCondition() {
    loading.value = true;
    const res = await getConditions.execute({ groupId: props.groupId });
    if (isSucceeded(res)) {
      users.value = res.users;
      contents.value = res.contents;
      groupExams.value = res.groupExams;
    }
    loading.value = false;
  }

  const getResults = useGetMyExamResults();
  async function fetchResults() {
    loading.value = true;
    const res = await getResults.execute({ groupId: props.groupId });
    if (isSucceeded(res)) results.value = res.examResults;
    else results.value = [];
    loading.value = false;
  }

  async function init() {
    await fetchCondition();
    condition.value = {
      userId: props.userId,
      contentId: props.contentId,
      times: props.times ? parseInt(props.times, 10) : Number.NaN,
    };
    fetchResults();
  }
  onMounted(init);
  watch(() => props.groupId, init);

  function findContent(id: string) {
    const exam = groupExams.value.find((item) => item.content.id === id);
    return {
      contentName: exam?.content.name || msgs.of('unknownContent', { id }).value,
      courseName: exam?.course.name || msgs.of('unknownCourse').value,
      indexInCourse: exam?.content.indexInCourse ?? 999,
    };
  }

  function findUser(id: string) {
    const user = users.value.find((item) => item.id === id);
    return { userName: user?.name || msgs.of('unknownUser', { id }).value };
  }

  function findGroupExam(id: string) {
    const exam = groupExams.value.find((item) => item.id === id);
    return { timeLimit: exam?.timeLimit ?? '-', times: exam?.times };
  }

  const tableKeys = computed(() => {
    const keys: string[] = [];
    if (!condition.value?.contentId) keys.push('courseName', 'contentName');
    if (!condition.value?.times) keys.push('times');
    keys.push('startAndEnd', 'timeLimit', 'passFail', 'passingStandard', 'score');
    return keys;
  });
  const problemSize = computed(() => {
    const { contentId, times } = condition.value;
    if (!contentId) return undefined;
    const counts = groupExams.value
      .filter(
        (item) =>
          item.content.id === contentId &&
          (!times || item.times === times) &&
          item.visibilityLevel === 'details'
      )
      .map((item) => item.content.problems.length);
    if (counts.length === 0) return undefined;
    return Math.max(...counts);
  });

  const items = computed(() => {
    const { contentId, times } = condition.value;
    const ret = results.value
      .filter(
        (item) =>
          (!contentId || item.contentId === contentId) &&
          (!times || Number.isNaN(times) || ('times' in item && item.times === times))
      )
      .map((r) => {
        const groupExam =
          'groupExamId' in r ? findGroupExam(r.groupExamId) : { timeLimit: '-', times: undefined };
        return {
          ...r,
          ...findContent(r.contentId),
          ...findUser(r.userId),
          ...groupExam,
        };
      });
    // モバイル表示の場合は開始日時の降順
    if (isMobile) return ret.sort((a, b) => (a.start.isAfter(b.start) ? -1 : 1));
    // デスクトップ表示の場合はテーブル列で昇順
    return ret.sort((a, b) => {
      const aTimes = a.times ?? -1;
      const bTimes = b.times ?? -1;
      if (a.courseName === b.courseName) {
        if (a.indexInCourse === b.indexInCourse) {
          if (aTimes === bTimes) return a.start.isBefore(b.start) ? -1 : 1;
          return aTimes < bTimes ? -1 : 1;
        }
        return a.indexInCourse - b.indexInCourse;
      }
      return a.courseName < b.courseName ? -1 : 1;
    });
  });

  const { breakpoint } = useVuetify();
  const tableHeight = computed(() => {
    let h = 86;
    if (breakpoint.value?.mdAndUp) h += 46;
    if (breakpoint.value?.smAndDown) h += 46 * 2;
    return `calc(100vh - ${props.topHeight + h}px)`;
  });

  return {
    results: items,
    users,
    contents,
    groupExams,
    loading,
    condition,
    returnQuery,
    tableKeys,
    problemSize,
    tableHeight,
    noData: msgs.of('noData'),
    refresh: fetchResults,
  };
}
