import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';

import { UserCorrectAnswerRate } from '../../../domains';
import { formatPassFail, formatRate, sortByNumber } from '../../utils/TableUtils';

export type UserUsageTraineeUserCorrectAnswerRate = UserCorrectAnswerRate;

export type PropsUserUsageTraineeUserCorrectAnswerRateTable = {
  userCorrectAnswerRateList: UserUsageTraineeUserCorrectAnswerRate[];
  headerKeys: string[];
};

const HEADERS: DataTableHeader[] = [
  { value: 'userName', text: '', class: 'base-table-name' },
  { value: 'correctAnswerRate', text: '', align: 'center', width: 250, sort: sortByNumber },
  { value: 'passFail', text: '', align: 'center', width: 150 },
];

export function useUserUsageTraineeUserCorrectAnswerRateTable(
  props: PropsUserUsageTraineeUserCorrectAnswerRateTable
) {
  const msgs = useMessages({
    prefix: 'report.molecules.userUsageTraineeUserCorrectAnswerRateTable',
  });

  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );

  const items = computed(() => {
    const messageNotStarted = msgs.of('notStarted').value;
    return props.userCorrectAnswerRateList
      .map((item) => ({
        ...item,
        correctAnswerRate: formatRate(item.correctAnswerRate) ?? messageNotStarted,
        passFail: msgs.of(formatPassFail(item)).value,
      }))
      .sort((a, b) => {
        if (a.userName === b.userName) return a.userId < b.userId ? -1 : 1;
        return a.userName < b.userName ? -1 : 1;
      });
  });

  return {
    headers,
    items,
  };
}

export type UserUsageTraineeUserCorrectAnswerRateTable = ReturnType<
  typeof useUserUsageTraineeUserCorrectAnswerRateTable
>;
