



















import { defineComponent } from '@vue/composition-api';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';

import UserUsageGroupExamTable from '../molecules/UserUsageGroupExamTable.vue';
import { useUserUsageGroupExamDialog } from './UserUsageGroupExamDialogComposable';

export default defineComponent({
  name: 'ReportUserUsageGroupExamDialog',
  components: {
    BaseDialogFullScreen,
    UserUsageGroupExamTable,
  },
  setup() {
    return useUserUsageGroupExamDialog();
  },
});
