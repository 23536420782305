



















import { defineComponent } from '@vue/composition-api';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';

import UserUsageTraineeProgressTable from '../molecules/UserUsageTraineeProgressTable.vue';
import { useUserUsageTraineeProgressDialog } from './UserUsageTraineeProgressDialogComposable';

export default defineComponent({
  name: 'ReportUserUsageTraineeProgressDialog',
  components: {
    BaseDialogFullScreen,
    UserUsageTraineeProgressTable,
  },
  setup() {
    return useUserUsageTraineeProgressDialog();
  },
});
