


































































































import { defineComponent } from '@vue/composition-api';

import ExamResultTable from '../molecules/ExamResultTable.vue';
import UserDataAndAverageBarChart from '../molecules/UserDataAndAverageBarChart.vue';
import UserProgressPercentageDoughnutChart from '../molecules/UserProgressPercentageDoughnutChart.vue';
import UserUsageCourseTable from '../molecules/UserUsageCourseTable.vue';
import UserUsageDetail from '../molecules/UserUsageDetail.vue';
import UserUsageSearchFields from '../molecules/UserUsageSearchFields.vue';
import UserUsageTraineeCourseTable from '../molecules/UserUsageTraineeCourseTable.vue';
import UserUsageTraineeExamTable from '../molecules/UserUsageTraineeExamTable.vue';
import UserUsageTraineeTable from '../molecules/UserUsageTraineeTable.vue';
import { UserUsageProps, useUserUsage } from './UserUsageComposable';
import UserUsageGroupCoursesDialog from './UserUsageGroupCoursesDialog.vue';
import UserUsageGroupExamDialog from './UserUsageGroupExamDialog.vue';
import UserUsageTraineeExamDialog from './UserUsageTraineeExamDialog.vue';
import UserUsageTraineeProgressDialog from './UserUsageTraineeProgressDialog.vue';

type Props = UserUsageProps;

export default defineComponent({
  name: 'ReportUserUsage',
  components: {
    UserUsageSearchFields,
    UserUsageDetail,
    UserDataAndAverageBarChart,
    UserProgressPercentageDoughnutChart,
    UserUsageCourseTable,
    ExamResultTable,
    UserUsageTraineeTable,
    UserUsageTraineeCourseTable,
    UserUsageTraineeExamTable,
    UserUsageGroupCoursesDialog,
    UserUsageGroupExamDialog,
    UserUsageTraineeProgressDialog,
    UserUsageTraineeExamDialog,
  },
  props: {
    admin: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useUserUsage(props);
  },
});
