
















import { defineComponent, PropType } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsUserUsageTraineeExamTable,
  UserUsageTraineeExamItem,
  useUserUsageTraineeExamTable,
} from './UserUsageTraineeExamTableComposable';

type Props = PropsUserUsageTraineeExamTable;
export default defineComponent({
  name: 'ReportUserUsageTraineeExamTable',
  components: { BaseButton, BaseTable },
  inheritAttrs: false,
  props: {
    groupId: { type: String, required: true },
    examRecords: { type: Array as PropType<UserUsageTraineeExamItem[]>, default: () => [] },
  },
  setup(props: Props) {
    return useUserUsageTraineeExamTable(props);
  },
});
