import { onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { delayScroll, escapeId } from '@/base/app/utils/DomUtils';
import { ExamResult, GroupExam, GroupId, UserId } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  GetTrainerUserReportResponse,
  GetTrainerUserReportResponseCourse,
  useGetGroupReportSearchConditions,
  useGetTrainerUserReport,
  useGetTrainerUserReportConditions,
  useGetTrainerUsersReport,
} from '../../../usecases';
import { PropsUserUsageDetail } from '../molecules/UserUsageDetailComposable';
import {
  UserUsageSearchFieldsConditionGroup,
  UserUsageSearchFieldsValue,
} from '../molecules/UserUsageSearchFieldsComposable';
import { PropsUserUsageTraineeCourseTable } from '../molecules/UserUsageTraineeCourseTableComposable';
import { PropsUserUsageTraineeExamTable } from '../molecules/UserUsageTraineeExamTableComposable';
import { PropsUserUsageTraineeTable } from '../molecules/UserUsageTraineeTableComposable';
import { UserUsageGroupCoursesDialog } from './UserUsageGroupCoursesDialogComposable';
import { UserUsageGroupExamDialog } from './UserUsageGroupExamDialogComposable';
import { UserUsageTraineeExamDialog } from './UserUsageTraineeExamDialogComposable';
import { UserUsageTraineeProgressDialog } from './UserUsageTraineeProgressDialogComposable';

const EXAM_RESULT_KEYS = ['courseName', 'contentName', 'times', 'startAndEnd', 'score'];
const EXAM_RESULT_KEYS_PASSED = [
  'courseName',
  'contentName',
  'times',
  'startAndEnd',
  'passFail',
  'passingStandard',
  'score',
];

type UserUsageAverageChart = {
  title: string;
  userData: number;
  usersAverage: number;
  unit?: string;
};

type UserUsageProgressChart = {
  title: string;
  userData: number[];
  labels: string[];
};

type UserUsageExamResult = Omit<ExamResult, 'times'> & {
  courseName: string;
  contentName: string;
  indexInCourse: number;
  times: string | number;
};

type UserUsageData = {
  detail: PropsUserUsageDetail;
  averageCharts: UserUsageAverageChart[];
  progressCharts: UserUsageProgressChart[];
  courses: GetTrainerUserReportResponseCourse[];
  examResults: UserUsageExamResult[];
  examResultKeys: string[];
};

function useUserUsageGroupCoursesDialog() {
  const userUsageGroupCoursesDialog = ref<UserUsageGroupCoursesDialog>();
  const { getQuery } = useDialogQuery(DialogName.REPORT_USER_USAGE_GROUP_COURSES);
  function watchGroupCoursesDialogQuery() {
    if (!userUsageGroupCoursesDialog.value) return false;
    const q = getQuery();
    if (q && q.params && 'groupId' in q.params && 'userId' in q.params) {
      userUsageGroupCoursesDialog.value.open(q.params.groupId, q.params.userId);
      return q.params.groupId;
    }
    if (!q && userUsageGroupCoursesDialog.value.opened()) {
      userUsageGroupCoursesDialog.value.close();
    }
    return false;
  }
  return { userUsageGroupCoursesDialog, watchGroupCoursesDialogQuery };
}

function useUserUsageGroupExamsDialog() {
  const userUsageGroupExamDialog = ref<UserUsageGroupExamDialog>();
  const { getQuery } = useDialogQuery(DialogName.REPORT_USER_USAGE_GROUP_EXAMS);
  function watchGroupExamsDialogQuery() {
    if (!userUsageGroupExamDialog.value) return false;
    const q = getQuery();
    if (q && q.params && 'groupId' in q.params && 'userId' in q.params) {
      userUsageGroupExamDialog.value.open(q.params.groupId, q.params.userId);
      return q.params.groupId;
    }
    if (!q && userUsageGroupExamDialog.value.opened()) {
      userUsageGroupExamDialog.value.close();
    }
    return false;
  }
  return { userUsageGroupExamDialog, watchGroupExamsDialogQuery };
}

function useUserUsageTraineeProgressDialog() {
  const userUsageTraineeProgressDialog = ref<UserUsageTraineeProgressDialog>();
  const { getQuery } = useDialogQuery(DialogName.REPORT_USER_USAGE_TRAINEE_PROGRESS);
  function watchTraineeProgressDialogQuery() {
    if (!userUsageTraineeProgressDialog.value) return false;
    const q = getQuery();
    if (q && q.params && 'groupId' in q.params && 'courseId' in q.params) {
      userUsageTraineeProgressDialog.value.open(q.params.groupId, q.params.courseId);
      return q.params.groupId;
    }
    if (!q && userUsageTraineeProgressDialog.value.opened()) {
      userUsageTraineeProgressDialog.value.close();
    }
    return false;
  }
  return { userUsageTraineeProgressDialog, watchTraineeProgressDialogQuery };
}

function useUserUsageTraineeExamDialog() {
  const userUsageTraineeExamDialog = ref<UserUsageTraineeExamDialog>();
  const { getQuery } = useDialogQuery(DialogName.REPORT_USER_USAGE_TRAINEE_EXAM);
  function watchTraineeExamDialogQuery() {
    if (!userUsageTraineeExamDialog.value) return false;
    const q = getQuery();
    if (
      q &&
      q.params &&
      'groupId' in q.params &&
      'contentId' in q.params &&
      'times' in q.params &&
      Number.isFinite(parseInt(q.params.times, 10))
    ) {
      userUsageTraineeExamDialog.value.open(
        q.params.groupId,
        q.params.contentId,
        parseInt(q.params.times, 10)
      );
      return q.params.groupId;
    }
    if (!q && userUsageTraineeExamDialog.value.opened()) {
      userUsageTraineeExamDialog.value.close();
    }
    return false;
  }
  return { userUsageTraineeExamDialog, watchTraineeExamDialogQuery };
}

export type UserUsageProps = {
  admin: boolean;
};

export function useUserUsage({ admin }: UserUsageProps) {
  const msgs = useMessages({ prefix: 'report.organisms.userUsage' });
  const route = useRoute();
  const router = useRouter();

  const { userUsageGroupCoursesDialog, watchGroupCoursesDialogQuery } =
    useUserUsageGroupCoursesDialog();
  const { userUsageGroupExamDialog, watchGroupExamsDialogQuery } = useUserUsageGroupExamsDialog();
  const { userUsageTraineeProgressDialog, watchTraineeProgressDialogQuery } =
    useUserUsageTraineeProgressDialog();
  const { userUsageTraineeExamDialog, watchTraineeExamDialogQuery } =
    useUserUsageTraineeExamDialog();

  const input = ref<UserUsageSearchFieldsValue>();
  const groups = ref<UserUsageSearchFieldsConditionGroup[]>();
  const usage = ref<UserUsageData>();
  const traineeRecords = ref<
    PropsUserUsageTraineeTable & PropsUserUsageTraineeCourseTable & PropsUserUsageTraineeExamTable
  >();
  const loading = ref(false);

  function formatUserName(user: { id: string; name?: string }) {
    if (!user.name) return msgs.of('unknownUser', { id: user.id }).value;
    return user.name;
  }

  function formatGroupName(group: { name: string; enabled: boolean }) {
    if (!group.enabled) return msgs.of('disabledGroup', { name: group.name }).value;
    return group.name;
  }

  const getConditions = useGetTrainerUserReportConditions();
  async function fetchConditions() {
    const res = await getConditions.execute({ admin });
    if (isFailed(res)) return undefined;
    return res.groups
      .map((item) => {
        const name = formatGroupName(item);
        const users = item.users
          .map((u) => ({ ...u, name: formatUserName(u) }))
          .sort((a, b) => (a.name < b.name ? -1 : 1));
        return { ...item, name, users };
      })
      .sort((a, b) => {
        if (a.enabled === b.enabled) return a.name < b.name ? -1 : 1;
        return b.enabled < a.enabled ? -1 : 1;
      });
  }

  function tryScroll() {
    if (!route.hash) return;
    delayScroll(escapeId(route.hash));
    router.replace({ hash: undefined });
  }

  function toDetail(userUsage: GetTrainerUserReportResponse) {
    return {
      startOfUse: userUsage.startOfUse,
      recentUsedAt: userUsage.recentUsedAt,
      usageTime: Math.round(userUsage.usageTime / 60),
      courseCount: userUsage.courseCount,
      contentCount: userUsage.contentCount,
      completedCourseCount: userUsage.completedCourseCount,
      completedContentCount: userUsage.completedContentCount,
      commentCount: userUsage.commentCount,
      completedExamCount: userUsage.completedExamCount,
    };
  }

  function createAverageCharts(userUsage: GetTrainerUserReportResponse) {
    const ret: UserUsageAverageChart[] = [];
    // コース利用時間
    ret.push({
      title: msgs.of('courseUsedTime').value,
      userData: Math.round(userUsage.usageTime / 60),
      usersAverage: userUsage.groupAverageUsageTime.div(60).toNumber(),
      unit: msgs.of('hour').value,
    });
    // コメント数
    ret.push({
      title: msgs.of('totalComments').value,
      userData: userUsage.commentCount,
      usersAverage: userUsage.groupAverageCommentCount.toNumber(),
    });
    return ret;
  }

  function createProgressCharts(userUsage: GetTrainerUserReportResponse) {
    const ret: UserUsageProgressChart[] = [];
    const completedOrNot = ['completed', 'notCompleted'].map((key) => msgs.of(key).value);
    const correctOrNot = ['correct', 'incorrect'].map((key) => msgs.of(key).value);
    // コース学習率
    ret.push({
      title: msgs.of('courseCompletedPercentage').value,
      userData: [
        userUsage.completedCourseCount,
        userUsage.courseCount - userUsage.completedCourseCount,
      ],
      labels: completedOrNot,
    });
    // コンテンツ進捗率
    ret.push({
      title: msgs.of('contentsProgressPercentage').value,
      userData: [
        userUsage.completedContentCount,
        userUsage.contentCount - userUsage.completedContentCount,
      ],
      labels: completedOrNot,
    });
    // テスト正答率
    ret.push({
      title: msgs.of('testCorrectAnswerRate').value,
      userData: [
        userUsage.correctAnswerCount,
        userUsage.examProblemCount - userUsage.correctAnswerCount,
      ],
      labels: correctOrNot,
    });
    return ret;
  }

  function toCourses(userUsage: GetTrainerUserReportResponse) {
    // コース一覧
    return [...userUsage.courses].sort((a, b) => {
      if (!a.recentUsedAt) return 1;
      return a.name < b.name ? -1 : 1;
    });
  }

  function toExamResults(groupExams: GroupExam[], userUsage: GetTrainerUserReportResponse) {
    const findContent = (id: string) => {
      const exam = groupExams.find((item) => item.content.id === id);
      return {
        contentName: exam?.content.name || msgs.of('unknownContent', { id }).value,
        courseName: exam?.course.name || msgs.of('unknownCourse').value,
        indexInCourse: exam?.content.indexInCourse ?? 999,
      };
    };
    // テスト一覧
    return userUsage.examResults
      .map((item) => ({
        ...item,
        ...findContent(item.contentId),
        times: 'times' in item ? item.times : '-',
      }))
      .sort((a, b) => {
        const aTimes = a.times === '-' ? -1 : a.times;
        const bTimes = b.times === '-' ? -1 : b.times;
        if (a.courseName === b.courseName) {
          if (a.indexInCourse === b.indexInCourse) {
            if (aTimes === bTimes) return a.start.isBefore(b.start) ? -1 : 1;
            return aTimes < bTimes ? -1 : 1;
          }
          return a.indexInCourse - b.indexInCourse;
        }
        return a.courseName < b.courseName ? -1 : 1;
      });
  }

  const getUsersReport = useGetTrainerUsersReport();
  async function getTraineesReport(groupId: GroupId) {
    usage.value = undefined;
    const resReports = await getUsersReport.execute({ groupId });
    if (isFailed(resReports)) {
      traineeRecords.value = undefined;
      return;
    }
    traineeRecords.value = {
      groupId,
      records: resReports.records,
      average: {
        groupAverageStartOfUse: resReports.groupAverageStartOfUse,
        groupAverageRecentUsedAt: resReports.groupAverageRecentUsedAt,
        groupAverageUsageTime: resReports.groupAverageUsageTime,
        groupAverageCommentCount: resReports.groupAverageCommentCount,
        groupAverageNotBegunCourseCount: resReports.groupAverageNotBegunCourseCount,
        groupAverageInProgressCourseCount: resReports.groupAverageInProgressCourseCount,
        groupAverageCompletedCourseCount: resReports.groupAverageCompletedCourseCount,
        groupAverageCourseProgressRate: resReports.groupAverageCourseProgressRate,
        groupAverageCorrectAnswerRate: resReports.groupAverageCorrectAnswerRate,
        groupAverageNotBegunExamCount: resReports.groupAverageNotBegunExamCount,
        groupAverageCompletedExamCount: resReports.groupAverageCompletedExamCount,
      },
      courseRecords: resReports.courses,
      courseAverage: resReports.courseAverages,
      examRecords: resReports.examResults,
    };
  }

  const getUserReport = useGetTrainerUserReport();
  const getGroupReport = useGetGroupReportSearchConditions();
  async function getReportBy(groupId: GroupId, userId: UserId) {
    traineeRecords.value = undefined;
    const [resGroup, resUser] = await Promise.all([
      getGroupReport.execute({ groupId }),
      getUserReport.execute({ groupId, userId }),
    ]);
    if (isFailed(resGroup) || isFailed(resUser)) {
      usage.value = undefined;
      return;
    }
    usage.value = {
      detail: toDetail(resUser),
      averageCharts: createAverageCharts(resUser),
      progressCharts: createProgressCharts(resUser),
      courses: toCourses(resUser),
      examResults: toExamResults(resGroup.groupExams, resUser),
      examResultKeys: resUser.examResults.some((item) => 'isPassed' in item)
        ? EXAM_RESULT_KEYS_PASSED
        : EXAM_RESULT_KEYS,
    };
  }

  async function search() {
    if (!input.value) return;
    const { groupId, userId } = input.value;
    loading.value = true;
    if (userId === 'trainees') {
      await getTraineesReport(groupId);
    } else {
      await getReportBy(groupId, userId);
    }
    loading.value = false;
  }

  function initDialog() {
    const groupId =
      watchGroupCoursesDialogQuery() ||
      watchGroupExamsDialogQuery() ||
      watchTraineeExamDialogQuery() ||
      watchTraineeProgressDialogQuery();
    if (!groupId) {
      if (!usage.value && !traineeRecords.value) search();
      else tryScroll();
      return;
    }
    if (!usage.value && !traineeRecords.value && groupId) {
      const group = groups.value?.find((g) => g.id === groupId);
      input.value = {
        userId: 'trainees',
        userName: msgs.of('trainees').value,
        groupId,
        groupName: group?.name ?? '-',
      };
    }
  }

  async function init() {
    loading.value = true;
    input.value = undefined;
    groups.value = await fetchConditions();
    loading.value = false;
  }

  onMounted(async () => {
    await init();
    initDialog();
  });
  watch(() => route.query, initDialog);

  return {
    input,
    groups,
    usage,
    traineeRecords,
    loading,
    userUsageGroupCoursesDialog,
    userUsageGroupExamDialog,
    userUsageTraineeProgressDialog,
    userUsageTraineeExamDialog,
    labelExamList: msgs.of('examList'),
    description: msgs.of('description'),
    traineeDescription: msgs.of('traineeDescription'),
    search,
  };
}
