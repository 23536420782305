














import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsQuestionnaireTable,
  QuestionnaireTableItem,
  useQuestionnaireTable,
} from './QuestionnaireTableComposable';

type Props = PropsQuestionnaireTable;

export default defineComponent({
  name: 'ReportQuestionnaireTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    questionnaires: { type: Array as PropType<QuestionnaireTableItem[]>, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionnaireTable(props, emit);
  },
});
