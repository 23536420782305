import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';

import { GetTrainerUserReportResponseCourse } from '../../../usecases';
import { formatHour, formatRate, formatYMD, sortByNumber } from '../../utils/TableUtils';

export type UserUsageCourseItem = GetTrainerUserReportResponseCourse;

export type PropsUserUsageCourseTable = {
  courses: UserUsageCourseItem[];
};

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', width: 300 },
  { value: 'startOfUse', text: '', width: 150 },
  { value: 'recentUsedAt', text: '', width: 150 },
  { value: 'usageTimeHour', text: '', align: 'center', width: 100 },
  { value: 'commentCount', text: '', align: 'center', width: 100 },
  { value: 'contentCount', text: '', align: 'center', width: 100 },
  { value: 'notBegunContentCount', text: '', align: 'center', width: 100 },
  { value: 'inProgressContentCount', text: '', align: 'center', width: 100 },
  { value: 'completedContentCount', text: '', align: 'center', width: 100 },
  { value: 'progressRate', text: '', align: 'center', width: 100, sort: sortByNumber },
  { value: 'completedExamCount', text: '', align: 'center', width: 100 },
  { value: 'correctAnswerRate', text: '', align: 'center', width: 100, sort: sortByNumber },
  { value: 'completedExamContentCount', text: '', align: 'center', width: 100 },
  { value: 'latestCorrectAnswerRate', text: '', align: 'center', width: 100, sort: sortByNumber },
];

export function useUserUsageCourseTable(props: PropsUserUsageCourseTable) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageCourseTable' });

  const headers = HEADERS.map((header) => ({
    ...header,
    text: msgs.of(header.value).value,
  }));

  const items = computed(() => {
    const messageNotStarted = msgs.of('notStarted').value;
    return props.courses.map((c) => ({
      ...c,
      usageTimeHour: formatHour(c.usageTime),
      startOfUse: formatYMD(c.startOfUse) ?? messageNotStarted,
      recentUsedAt: formatYMD(c.recentUsedAt) ?? messageNotStarted,
      progressRate: formatRate(c.progressRate),
      correctAnswerRate: formatRate(c.correctAnswerRate),
      latestCorrectAnswerRate: formatRate(c.latestCorrectAnswerRate),
    }));
  });

  return { headers, items, labelTitle: msgs.of('title') };
}
