import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';

import { UserExamCorrections } from '../../../domains';
import { formatRate, sortByNumber } from '../../utils/TableUtils';

export type UserUsageGroupExamItem = UserExamCorrections;

export type PropsUserUsageGroupExamTable = {
  exams: UserUsageGroupExamItem[];
};

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'examName', text: '', class: 'base-table-name' },
  { value: 'times', text: '', align: 'center', width: 100, sort: sortByNumber },
  { value: 'correctAnswerRate', text: '', align: 'center', width: 200, sort: sortByNumber },
];

export function useUserUsageGroupExamTable(props: PropsUserUsageGroupExamTable) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageGroupExamTable' });

  const headers = HEADERS.map((header) => ({
    ...header,
    text: msgs.of(header.value).value,
  }));

  const items = computed(() => {
    return props.exams
      .map((c) => ({
        ...c,
        correctAnswerRate: formatRate(c.correctAnswerRate),
      }))
      .sort((a, b) => {
        if (a.name === b.name) {
          if (a.examName === b.examName) {
            if (a.times === b.times) return a.id < b.id ? -1 : 1;
            return a.times < b.times ? -1 : 1;
          }
          return a.examName < b.examName ? -1 : 1;
        }
        return a.name < b.name ? -1 : 1;
      });
  });

  return {
    headers,
    items,
  };
}

export type UserUsageGroupExamTable = ReturnType<typeof useUserUsageGroupExamTable>;
