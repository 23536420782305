













import { defineComponent } from '@vue/composition-api';

import ProblemResultsView from '@/base/app/components/molecules/ProblemResultsView.vue';
import UserExamResultSummary from '@/base/app/components/molecules/UserExamResultSummary.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';

import { PropsGroupUserExamResult, useGroupUserExamResult } from './GroupUserExamResultComposable';

type Props = PropsGroupUserExamResult;

export default defineComponent({
  name: 'ReportGroupUserExamResult',
  components: { UserExamResultSummary, ProblemResultsView, DialogAnchorConfirm },
  props: {
    id: { type: String, required: true },
  },
  setup(props: Props) {
    return useGroupUserExamResult(props);
  },
});
