




















import { computed, defineComponent, provide } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import ScrollToTopButton from '@/base/app/components/atoms/ScrollToTopButton.vue';
import MyApp from '@/base/app/components/MyApp.vue';

import GroupUserExamResult from '../components/organisms/GroupUserExamResult.vue';
import { UserExamResultStoreKey, useUserExamResultStore } from '../stores';

export default defineComponent({
  name: 'ReportGroupUserExamResultPage',
  components: { MyApp, GroupUserExamResult, ScrollToTopButton },
  props: {
    id: { type: String, required: true },
    examId: { type: String, default: undefined },
  },
  setup() {
    const store = useUserExamResultStore();
    provide(UserExamResultStoreKey, store);

    const msgs = useMessages({ prefix: 'report.pages.groupUserExamResultPage' });
    const label = computed(() => {
      if (!store.exam.value || !store.result.value) return undefined;
      const { name } = store.exam.value;
      const times = store.result.value.version === 1 ? undefined : store.result.value.times;
      return msgs.of('examName', { name, times: times ?? '-' }).value;
    });

    return { loading: store.loading, notFound: store.notFound, label };
  },
});
