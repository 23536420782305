



import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsUserUsageTraineeUserCorrectAnswerRateTable,
  UserUsageTraineeUserCorrectAnswerRate,
  useUserUsageTraineeUserCorrectAnswerRateTable,
} from './UserUsageTraineeUserCorrectAnswerRateTableComposable';

type Props = PropsUserUsageTraineeUserCorrectAnswerRateTable;

export default defineComponent({
  name: 'ReportUserUsageTraineeUserCorrectAnswerRateTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    userCorrectAnswerRateList: {
      type: Array as PropType<UserUsageTraineeUserCorrectAnswerRate[]>,
      default: () => [],
    },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => ['userName', 'correctAnswerRate'],
    },
  },
  setup(props: Props) {
    return useUserUsageTraineeUserCorrectAnswerRateTable(props);
  },
});
