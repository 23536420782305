






import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsUserUsageCourseTable,
  UserUsageCourseItem,
  useUserUsageCourseTable,
} from './UserUsageCourseTableComposable';

type Props = PropsUserUsageCourseTable;
export default defineComponent({
  name: 'ReportUserUsageCourseTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    courses: { type: Array as PropType<UserUsageCourseItem[]>, required: true },
  },
  setup(props: Props) {
    return useUserUsageCourseTable(props);
  },
});
