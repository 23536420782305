



import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsUserUsageTraineeProgressTable,
  UserUsageTraineeProgressItem,
  useUserUsageTraineeProgressTable,
} from './UserUsageTraineeProgressTableComposable';

type Props = PropsUserUsageTraineeProgressTable;

export default defineComponent({
  name: 'ReportUserUsageTraineeProgressTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    users: { type: Array as PropType<UserUsageTraineeProgressItem[]>, default: () => [] },
  },
  setup(props: Props) {
    return useUserUsageTraineeProgressTable(props);
  },
});
