












































import { defineComponent, PropType } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import { LocalDateTime } from '@/base/types';

import { PropsExamResultCard, useExamResultCard } from './ExamResultCardComposable';

type Props = PropsExamResultCard;

export default defineComponent({
  name: 'ReportExamResultCard',
  components: { BaseButton },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    start: { type: Object as PropType<LocalDateTime>, required: true },
    end: { type: Object as PropType<LocalDateTime>, required: true },
    contentId: { type: String, required: true },
    userId: { type: String, required: true },
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    score: { type: Number, default: undefined },
    problemCount: { type: Number, default: undefined },
    answers: { type: Array, default: undefined },
    isPassed: { type: Boolean, default: undefined },
    passingStandard: { type: Number, default: undefined },
    visibilityLevel: { type: String, required: true },
    times: { type: Number, default: undefined },
    groupExamId: { type: String, required: true },
    courseName: { type: String, required: true },
    contentName: { type: String, required: true },
    userName: { type: String, required: true },
    indexInCourse: { type: Number, default: undefined },
    timeLimit: { type: [Number, String], default: undefined },
    returnQuery: { type: Object, default: undefined },
  },
  setup(props: Props) {
    return useExamResultCard(props);
  },
});
