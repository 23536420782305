

















import { defineComponent, PropType } from '@vue/composition-api';
import { DoughnutChart } from 'vue-chart-3';

import {
  PropsUserProgressPercentageDoughnutChart,
  useUserProgressPercentageDoughnutChart,
} from './UserProgressPercentageDoughnutChartComposable';

type Props = PropsUserProgressPercentageDoughnutChart;

export default defineComponent({
  name: 'ReportUserProgressPercentageDoughnutChart',
  components: {
    DoughnutChart,
  },
  props: {
    title: { type: String, default: '' },
    userData: { type: Array as PropType<number[]>, default: () => [] },
    labels: { type: Array as PropType<string[]>, default: () => [] },
    percentageClass: { type: String, default: 'text-h3' },
    titleClass: { type: String, default: 'text-caption' },
  },
  setup(props: Props) {
    return useUserProgressPercentageDoughnutChart(props);
  },
});
