
























import { defineComponent } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';

import ExamResultCard from '../molecules/ExamResultCard.vue';
import ExamResultUserSearchFields from '../molecules/ExamResultUserSearchFields.vue';
import {
  PropsGroupUserExamResults,
  useGroupUserExamResults,
} from './GroupUserExamResultsComposable';

type Props = PropsGroupUserExamResults;
export default defineComponent({
  name: 'ReportGroupUserExamResultsMobile',
  components: { ExamResultUserSearchFields, RefreshButton, ExamResultCard },
  props: {
    groupId: { type: String, required: true },
    userId: { type: String, required: true },
    contentId: { type: String, default: undefined },
    times: { type: String, default: undefined },
    topHeight: { type: Number, default: 64 },
  },
  setup(props: Props) {
    return useGroupUserExamResults(props, true);
  },
});
