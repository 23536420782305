




































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { GroupExam } from '@/base/domains';

import {
  GetGroupReportSearchConditionsContent,
  GetGroupReportSearchConditionsUser,
} from '../../../usecases';
import {
  ExamResultContentSearchFieldsCondition,
  PropsExamResultContentSearchFields,
  useExamResultContentSearchFields,
} from './ExamResultContentSearchFieldsComposable';

type Props = PropsExamResultContentSearchFields;

export default defineComponent({
  name: 'ReportExamResultContentSearchFields',
  components: {},
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<ExamResultContentSearchFieldsCondition>, default: undefined },
    contents: {
      type: Array as PropType<GetGroupReportSearchConditionsContent[]>,
      default: () => [],
    },
    users: { type: Array as PropType<GetGroupReportSearchConditionsUser[]>, default: () => [] },
    groupExams: { type: Array as PropType<GroupExam[]>, default: () => [] },
  },
  emits: ['change', 'refresh'],
  setup(props: Props, { emit }: SetupContext) {
    return useExamResultContentSearchFields(props, emit);
  },
});
