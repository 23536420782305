





















import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  ExamResultTableItem,
  PropsExamResultTable,
  useExamResultTable,
} from './ExamResultTableComposable';

type Props = PropsExamResultTable;

export default defineComponent({
  name: 'ReportExamResultTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    results: { type: Array as PropType<ExamResultTableItem[]>, default: () => [] },
    headerKeys: { type: Array as PropType<string[]>, default: () => [] },
    fixedVisibilityLevel: { type: String, default: undefined },
    problemSize: { type: Number, default: undefined },
    problemIndex: { type: Number, default: undefined },
    title: { type: String, default: undefined },
  },
  setup(props: Props) {
    return useExamResultTable(props);
  },
});
