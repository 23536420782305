import { ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { GroupId, UserId, UserName } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { UserCourseProgresses } from '../../../domains';
import { useGetUserCourseProgresses } from '../../../usecases';

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function info(msg: string, ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg, ok);
  }
  return { dialogFullScreen, dialog, opened, info };
}

export function useUserUsageGroupCoursesDialog() {
  const msgs = useMessages({ prefix: 'report.organisms.userUsageGroupCoursesDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, info } = useDialogFullScreen();

  const reports = ref<UserCourseProgresses[]>([]);
  const userName = ref<UserName>('');
  const loading = ref(false);

  const getReports = useGetUserCourseProgresses();
  async function fetch(groupId: GroupId, userId: UserId) {
    loading.value = true;
    const res = await getReports.execute({ groupId, userId });
    loading.value = false;
    if (isFailed(res)) return false;
    reports.value = res.courses;
    userName.value = res.userName;
    return true;
  }

  function close() {
    dialog.value = { display: false };
    reports.value = [];
    userName.value = '';
    const to = clearDialogQuery(route, '#userUsageTraineeTable');
    if (to) router.replace(to);
  }

  async function open(groupId: GroupId, userId: UserId) {
    dialog.value = { display: true };
    const ret = await fetch(groupId, userId);
    if (!ret) info(msgs.of('noData').value, close);
  }

  return {
    dialogFullScreen,
    dialog,
    loading,
    items: reports,
    title: msgs.of('userUsageCourses'),
    subtitle: userName,
    close,
    open,
    opened,
  };
}

export type UserUsageGroupCoursesDialog = ReturnType<typeof useUserUsageGroupCoursesDialog>;
