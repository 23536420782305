

















































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseAutoComplete from '@/base/app/components/atoms/BaseAutoComplete.vue';
import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseSelectSingle from '@/base/app/components/atoms/BaseSelectSingle.vue';

import {
  PropsUserUsageSearchFields,
  UserUsageSearchFieldsConditionGroup,
  UserUsageSearchFieldsValue,
  useUserUsageSearchFields,
} from './UserUsageSearchFieldsComposable';

type Props = PropsUserUsageSearchFields;

export default defineComponent({
  name: 'ReportUserUsageSearchFields',
  components: { BaseAutoComplete, BaseButton, BaseSelectSingle },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<UserUsageSearchFieldsValue>, default: undefined },
    groups: { type: Array as PropType<UserUsageSearchFieldsConditionGroup[]>, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserUsageSearchFields(props, emit);
  },
});
