import { computed } from '@vue/composition-api';

import { useChartConfig } from '@/base/app/utils/ChartUtils';

export type PropsUserProgressPercentageDoughnutChart = {
  userData: number[];
  labels: string[];
};

export function useUserProgressPercentageDoughnutChart(
  props: PropsUserProgressPercentageDoughnutChart
) {
  const { getChartColors } = useChartConfig();

  const data = computed(() => {
    return {
      labels: props.labels,
      datasets: [
        {
          data: props.userData,
          backgroundColor: getChartColors(props.userData.length),
          borderWidth: 0,
        },
      ],
    };
  });

  const options = {
    cutout: '75%',
    maintainAspectRatio: true,
    plugins: {
      legend: { display: false },
    },
  };

  const style = computed(() => ({
    'min-height': '250px',
    width: '250px',
  }));

  const percentage = computed(() => {
    const total = props.userData.reduce((sum, currentValue) => sum + currentValue);
    if (total === 0) return 0;
    return Math.round((props.userData[0] / total) * 100);
  });

  return { data, options, style, percentage };
}

export type UserProgressPercentageDoughnutChart = ReturnType<
  typeof useUserProgressPercentageDoughnutChart
>;
