var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 mb-3"},[_vm._v(_vm._s(_vm.labelTitle))]),_c('v-row',[_c('v-col',[_c('base-table',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"courseId","fixed-header":""},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
var isMobile = ref.isMobile;
var originalItemsLength = ref.originalItemsLength;
return [(originalItemsLength > 0)?_c('tr',{class:{ 'user-usage-trainee-course-table-average': !isMobile }},_vm._l((headers),function(header,index){return _c('td',{key:index,class:{
                    'v-data-table__mobile-row': isMobile,
                    'text-center': !isMobile && header.align,
                  }},[(isMobile)?_c('span',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(header.text)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getAverage(header.value))+" ")])}),0):_vm._e()]}},{key:"item.courseDetail",fn:function(ref){
                  var item = ref.item;
return [_c('base-button',{attrs:{"to":item.toCourseDetail,"outlined":false,"icon":"","exact":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dock-window")])],1)]}}],null,true)},'v-data-table',attrs,false),on))]}}])},'base-table',_vm.$attrs,false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }