















import { computed, defineComponent } from '@vue/composition-api';

import { useMessages } from '../../Messages';

type Props = {
  value?: string | boolean;
  label?: string;
  dense: boolean;
  outlined: boolean;
  veeRules?: string;
  veeVid?: string;
  veeLabel?: string;
};

export default defineComponent({
  name: 'BaseBaseAutoComplete',
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: String, default: undefined },
    label: { type: String, default: undefined },
    dense: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true },
    veeRules: { type: String, default: undefined },
    veeVid: { type: String, default: undefined },
    veeLabel: { type: String, default: undefined },
  },
  emits: ['change'],
  setup(props: Props) {
    const required = computed(
      () => props.veeRules && props.veeRules.split('|').includes('required')
    );
    const msgs = useMessages({ prefix: 'base.atoms.baseAutoComplete' });
    const veeName = computed(() => props.veeLabel || props.label || msgs.of('defaultLabel').value);
    return { required, veeName };
  },
});
