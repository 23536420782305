import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { GroupId } from '@/base/domains';

import { GroupCourseAverage, GroupCourseReport } from '../../../domains';
import { formatHour, formatRate, formatYMD, sortByNumber } from '../../utils/TableUtils';

export type UserUsageTraineeCourseItem = GroupCourseReport;

export type UserUsageTraineeAverage = GroupCourseAverage;
export type PropsUserUsageTraineeCourseTable = {
  groupId: GroupId;
  courseRecords: Array<UserUsageTraineeCourseItem>;
  courseAverage: UserUsageTraineeAverage;
};

const HEADERS: DataTableHeader[] = [
  { value: 'courseName', text: '', class: 'user-usage-trainee-course-table-name' },
  { value: 'startOfUse', text: '', width: 150 },
  { value: 'recentUsedAt', text: '', width: 150 },
  { value: 'usageTimeHour', text: '', align: 'center', width: 140 },
  { value: 'commentCount', text: '', align: 'center', width: 100 },
  { value: 'notBegunContentCount', text: '', align: 'center', width: 100 },
  { value: 'inProgressContentCount', text: '', align: 'center', width: 100 },
  { value: 'completedContentCount', text: '', align: 'center', width: 100 },
  { value: 'courseDetail', text: '', align: 'center', width: 120, sortable: false },
  { value: 'notBegunExamCount', text: '', align: 'center', width: 100 },
  { value: 'completedExamCount', text: '', align: 'center', width: 100 },
  { value: 'correctAnswerRate', text: '', align: 'center', width: 100, sort: sortByNumber },
];

export function useUserUsageTraineeCourseTable(props: PropsUserUsageTraineeCourseTable) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageTraineeCourseTable' });
  const { getLocation } = useDialogQuery(DialogName.REPORT_USER_USAGE_TRAINEE_PROGRESS);

  const headers = HEADERS.map((header) => ({
    ...header,
    text: msgs.of(header.value).value,
  }));

  const items = computed(() => {
    const messageNotStarted = msgs.of('notStarted').value;
    return props.courseRecords
      .map((c) => ({
        ...c,
        usageTimeHour: formatHour(c.groupUsageTime),
        startOfUse: formatYMD(c.startOfUse) ?? messageNotStarted,
        recentUsedAt: formatYMD(c.recentUsedAt) ?? messageNotStarted,
        correctAnswerRate: formatRate(c.correctAnswerRate),
        toCourseDetail: getLocation({ groupId: props.groupId, courseId: c.courseId }),
      }))
      .sort((a, b) => {
        if (a.courseName === b.courseName) return a.courseId < b.courseId ? -1 : 1;
        return a.courseName < b.courseName ? -1 : 1;
      });
  });

  function getAverage(key: string) {
    switch (key) {
      case 'courseName':
        return msgs.of('average').value;
      case 'startOfUse':
        return (
          formatYMD(props.courseAverage.courseAverageStartOfUse) ?? msgs.of('notStarted').value
        );
      case 'recentUsedAt':
        return (
          formatYMD(props.courseAverage.courseAverageRecentUsedAt) ?? msgs.of('notStarted').value
        );
      case 'usageTimeHour':
        return formatHour(props.courseAverage.courseAverageUsageTime);
      case 'commentCount':
        return props.courseAverage.courseAverageCommentCount;
      case 'notBegunContentCount':
        return props.courseAverage.courseAverageNotBegunContentCount;
      case 'inProgressContentCount':
        return props.courseAverage.courseAverageInProgressContentCount;
      case 'completedContentCount':
        return props.courseAverage.courseAverageCompletedContentCount;
      case 'correctAnswerRate':
        return formatRate(props.courseAverage.courseAverageCorrectAnswerRate);
      case 'notBegunExamCount':
        return props.courseAverage.courseAverageNotBegunExamCount;
      case 'completedExamCount':
        return props.courseAverage.courseAverageCompletedExamCount;
      default:
        return undefined;
    }
  }

  return { headers, items, labelTitle: msgs.of('courseList'), getAverage };
}
