






















import { computed, defineComponent, provide } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import QuestionnaireResult from '../components/organisms/QuestionnaireResult.vue';
import { QuestionnaireStoreKey, useQuestionnaireStore } from '../stores';

export default defineComponent({
  name: 'ReportGroupQuestionnairePage',
  components: { MyApp, QuestionnaireResult },
  props: {
    id: { type: String, required: true },
    questionnaireId: { type: String, required: true },
  },
  setup() {
    const store = useQuestionnaireStore();
    provide(QuestionnaireStoreKey, store);

    const msgs = useMessages({ prefix: 'report.pages.groupQuestionnairePage' });
    const label = computed(() => {
      if (!store.questionnaire.value) return undefined;
      const { title, status } = store.questionnaire.value;
      if (status === 'finished') return title;
      return msgs.of('titleActive', { title }).value;
    });

    return { loading: store.loading, notFound: store.notFound, label };
  },
});
