
















import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import GroupExamResults from '../components/organisms/GroupExamResults.vue';

export default defineComponent({
  name: 'ReportGroupExamResultHistoryPage',
  components: { MyApp, GroupExamResults },
  props: {
    id: { type: String, required: true },
    contentId: { type: String, default: undefined },
    times: { type: String, default: undefined },
  },
  setup() {
    return {};
  },
});
