




















import { defineComponent } from '@vue/composition-api';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';

import UserUsageTraineeUserCorrectAnswerRateTable from '../molecules/UserUsageTraineeUserCorrectAnswerRateTable.vue';
import { useUserUsageTraineeExamDialog } from './UserUsageTraineeExamDialogComposable';

export default defineComponent({
  name: 'ReportUserUsageTraineeExamDialog',
  components: {
    BaseDialogFullScreen,
    UserUsageTraineeUserCorrectAnswerRateTable,
  },
  setup() {
    return useUserUsageTraineeExamDialog();
  },
});
