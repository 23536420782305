import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import {
  ExamResult,
  ExamResultAnswer,
  ExamResultVisibilityLevel,
  MyExamResult,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { isDefined } from '@/utils/TsUtils';

import { formatPassFail, sortByNumber } from '../../utils/TableUtils';

const HEADERS: DataTableHeader[] = [
  { value: 'userName', text: '', class: 'report-exam-result-table-name' },
  { value: 'score', text: '', align: 'center', width: 120 },
  { value: 'passingStandard', text: '', align: 'center', width: 120 },
  { value: 'startAndEnd', text: '', class: 'report-exam-result-table-start-and-end' },
  { value: 'start', text: '', class: 'base-table-datetime' },
  { value: 'end', text: '', class: 'base-table-datetime' },
  { value: 'courseName', text: '', class: 'report-exam-result-table-name' },
  { value: 'contentName', text: '', class: 'report-exam-result-table-name' },
  {
    value: 'times',
    text: '',
    align: 'center',
    class: 'report-exam-result-table-name',
    sort: sortByNumber,
  },
  { value: 'level', text: '', width: 160 },
  { value: 'timeLimit', text: '', align: 'center', width: 100 },
  { value: 'passFail', text: '', align: 'center', width: 100 },
];

const ANSWERS_HEADER = { align: 'start', class: 'report-exam-result-table-answers' };

function formatScore(score: Optional<number>, count: number, level: ExamResultVisibilityLevel) {
  if (level === 'details' || level === 'score') {
    if (!isDefined(score)) return undefined;
    if (!score || !count) return 0;
    return Math.round((score / count) * 1000) / 10;
  }
  return '-';
}

function formatAnswer(answer: Optional<ExamResultAnswer>) {
  if (!answer) return [];
  return answer.values
    .filter((item) => item !== null && item !== undefined)
    .map((item) => {
      if (typeof item === 'number') return item + 1;
      return item;
    });
}

export type ExamResultTableItem = (ExamResult | MyExamResult) & {
  courseName: string;
  contentName: string;
  indexInCourse: number;
  userName: string;
};

export type PropsExamResultTable = {
  results: ExamResultTableItem[];
  headerKeys: string[];
  problemSize?: number;
  problemIndex?: number;
  fixedVisibilityLevel?: ExamResultVisibilityLevel;
  height?: string;
  loading: boolean;
  storeOptionId: string;
};

export function useExamResultTable(props: PropsExamResultTable) {
  const msgs = useMessages({ prefix: 'report.molecules.examResultTable' });
  const headers = computed(() => {
    const keys = props.headerKeys
      .map((value) => HEADERS.find((item) => item.value === value))
      .filter((item) => !!item)
      .map((item) => ({
        ...item,
        text: item ? msgs.of(item.value).value : '',
      })) as DataTableHeader[];
    if (Number.isFinite(props.problemSize)) {
      const filterIndex = Number.isFinite(props.problemIndex)
        ? `answer_${props.problemIndex}`
        : undefined;
      const answers = [...Array(props.problemSize)]
        .map((_, i) => ({
          value: `answer_${i}`,
          text: msgs.of('problemNo', { no: i + 1 }).value,
          ...ANSWERS_HEADER,
        }))
        .filter((item) => !filterIndex || item.value === filterIndex) as DataTableHeader[];
      keys.push(...answers);
    }
    return keys;
  });
  const items = computed(() => {
    const ret = props.results.map((item) => {
      const score =
        formatScore(
          'score' in item ? item.score : undefined,
          item.problemCount,
          props.fixedVisibilityLevel || item.visibilityLevel
        ) ?? '-';
      const passingStandard =
        formatScore(
          'passingStandard' in item ? item.passingStandard : undefined,
          item.problemCount,
          props.fixedVisibilityLevel || item.visibilityLevel
        ) ?? '-';
      const start = item.start?.format('YY/MM/DD HH:mm');
      const end = item.end?.format('MM/DD HH:mm');
      const startAndEnd = [start, end].filter((v) => v).join(' - ');
      const level = msgs.of(`level_${item.visibilityLevel}`).value;
      const passFail = msgs.of(formatPassFail(item)).value;
      return { ...item, score, passingStandard, start, end, startAndEnd, level, passFail };
    });
    if (!Number.isFinite(props.problemSize)) return ret;
    const none = msgs.of('answer_none').value;
    return ret.map((item) => {
      const level = props.fixedVisibilityLevel || item.visibilityLevel;
      if (level !== 'details' || !('answers' in item)) return item;
      const noAnswers = Object.fromEntries(
        [...Array(item.problemCount)]
          .map((_, i) => i)
          .filter((index) => !item.answers.some((ia) => ia.index === index))
          .map((index) => [`answer_${index}`, none])
      );
      const answers = Object.fromEntries(
        item.answers.map((a) => {
          const key = a.correct ? 'answer_correct' : 'answer_incorrect';
          const v = msgs.of(key, { values: formatAnswer(a).join() }).value;
          return [`answer_${a.index}`, v];
        })
      );
      return { ...item, ...noAnswers, ...answers };
    });
  });

  return { headers, items };
}
