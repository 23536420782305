



















import { defineComponent } from '@vue/composition-api';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';

import UserUsageGroupCourseTable from '../molecules/UserUsageGroupCourseTable.vue';
import { useUserUsageGroupCoursesDialog } from './UserUsageGroupCoursesDialogComposable';

export default defineComponent({
  name: 'ReportUserUsageGroupCoursesDialog',
  components: {
    BaseDialogFullScreen,
    UserUsageGroupCourseTable,
  },
  setup() {
    return useUserUsageGroupCoursesDialog();
  },
});
