



import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsUserUsageGroupCourseTable,
  UserUsageGroupCourseItem,
  useUserUsageGroupCourseTable,
} from './UserUsageGroupCourseTableComposable';

type Props = PropsUserUsageGroupCourseTable;

export default defineComponent({
  name: 'ReportUserUsageGroupCourseTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    courses: { type: Array as PropType<UserUsageGroupCourseItem[]>, default: () => [] },
  },
  setup(props: Props) {
    return useUserUsageGroupCourseTable(props);
  },
});
