import { computed } from '@vue/composition-api';
import BigNumber from 'bignumber.js';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { GroupId } from '@/base/domains';
import { LocalDate, LocalDateTime } from '@/base/types';

import { TrainerUserReportRecord } from '../../../domains';
import { formatHour, formatRate, formatYMD, sortByNumber } from '../../utils/TableUtils';

export type UserUsageTraineeItem = TrainerUserReportRecord;

export type UserUsageTraineeAverage = {
  groupAverageStartOfUse?: LocalDate;
  groupAverageRecentUsedAt?: LocalDateTime;
  groupAverageUsageTime: BigNumber;
  groupAverageCommentCount: BigNumber;
  groupAverageNotBegunCourseCount: BigNumber;
  groupAverageInProgressCourseCount: BigNumber;
  groupAverageCompletedCourseCount: BigNumber;
  groupAverageCourseProgressRate: BigNumber;
  groupAverageCorrectAnswerRate: BigNumber;
  groupAverageNotBegunExamCount: BigNumber;
  groupAverageCompletedExamCount: BigNumber;
};

export type PropsUserUsageTraineeTable = {
  groupId: GroupId;
  records: Array<UserUsageTraineeItem>;
  average: UserUsageTraineeAverage;
};

const HEADERS: DataTableHeader[] = [
  { value: 'userName', text: '', class: 'base-table-name' },
  { value: 'startOfUse', text: '', width: 150 },
  { value: 'recentUsedAt', text: '', width: 150 },
  { value: 'usageTimeHour', text: '', align: 'center', width: 140 },
  { value: 'commentCount', text: '', align: 'center', width: 100 },
  { value: 'notBegunCourseCount', text: '', align: 'center', width: 130 },
  { value: 'inProgressCourseCount', text: '', align: 'center', width: 130 },
  { value: 'completedCourseCount', text: '', align: 'center', width: 130 },
  { value: 'progressRate', text: '', align: 'center', width: 130, sort: sortByNumber },
  { value: 'courseDetail', text: '', align: 'center', width: 120, sortable: false },
  { value: 'notBegunExamCount', text: '', align: 'center', width: 100 },
  { value: 'completedExamCount', text: '', align: 'center', width: 100 },
  { value: 'correctAnswerRate', text: '', align: 'center', width: 100, sort: sortByNumber },
  { value: 'examDetail', text: '', align: 'center', width: 120, sortable: false },
];

export function useUserUsageTraineeTable(props: PropsUserUsageTraineeTable) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageTraineeTable' });
  const { getLocation: getCoursesLocation } = useDialogQuery(
    DialogName.REPORT_USER_USAGE_GROUP_COURSES
  );
  const { getLocation: getExamsLocation } = useDialogQuery(
    DialogName.REPORT_USER_USAGE_GROUP_EXAMS
  );

  const headers = HEADERS.map((header) => ({
    ...header,
    text: msgs.of(header.value).value,
  }));

  const items = computed(() => {
    const messageNotStarted = msgs.of('notStarted').value;
    return props.records.map((c) => ({
      ...c,
      usageTimeHour: formatHour(c.usageTime),
      startOfUse: formatYMD(c.startOfUse) ?? messageNotStarted,
      recentUsedAt: formatYMD(c.recentUsedAt) ?? messageNotStarted,
      progressRate: formatRate(c.courseProgressRate),
      correctAnswerRate: formatRate(c.correctAnswerRate),
      toCourseDetail: getCoursesLocation({ groupId: props.groupId, userId: c.userId }),
      toExamDetail: getExamsLocation({ groupId: props.groupId, userId: c.userId }),
    }));
  });

  function getAverage(key: string) {
    switch (key) {
      case 'userName':
        return msgs.of('average').value;
      case 'startOfUse':
        return formatYMD(props.average.groupAverageStartOfUse) ?? msgs.of('notStarted').value;
      case 'recentUsedAt':
        return formatYMD(props.average.groupAverageRecentUsedAt) ?? msgs.of('notStarted').value;
      case 'usageTimeHour':
        return formatHour(props.average.groupAverageUsageTime);
      case 'commentCount':
        return props.average.groupAverageCommentCount;
      case 'notBegunCourseCount':
        return props.average.groupAverageNotBegunCourseCount;
      case 'inProgressCourseCount':
        return props.average.groupAverageInProgressCourseCount;
      case 'completedCourseCount':
        return props.average.groupAverageCompletedCourseCount;
      case 'progressRate':
        return formatRate(props.average.groupAverageCourseProgressRate);
      case 'notBegunExamCount':
        return props.average.groupAverageNotBegunExamCount;
      case 'completedExamCount':
        return props.average.groupAverageCompletedExamCount;
      case 'correctAnswerRate':
        return formatRate(props.average.groupAverageCorrectAnswerRate);
      default:
        return undefined;
    }
  }

  return {
    headers,
    items,
    labelTitle: msgs.of('traineeList'),
    getAverage,
  };
}
