









import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import Questionnaires from '../components/organisms/Questionnaires.vue';

export default defineComponent({
  name: 'ReportGroupQuestionnairesPage',
  components: { MyApp, Questionnaires },
  props: {
    id: { type: String, required: true },
  },
  setup() {
    return {};
  },
});
