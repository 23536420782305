import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { GroupId } from '@/base/domains';

import { GroupExamResultReport } from '../../../domains';
import { formatRate, sortByNumber } from '../../utils/TableUtils';

export type UserUsageTraineeExamItem = GroupExamResultReport;

export type PropsUserUsageTraineeExamTable = {
  groupId: GroupId;
  examRecords: Array<UserUsageTraineeExamItem>;
};

const HEADERS: DataTableHeader[] = [
  { value: 'courseName', text: '', class: 'base-table-name' },
  { value: 'examName', text: '', class: 'base-table-name' },
  { value: 'times', text: '', align: 'center', width: 100, sort: sortByNumber },
  { value: 'correctAnswerRate', text: '', align: 'center', width: 130, sort: sortByNumber },
  { value: 'examCount', text: '', align: 'center', width: 100 },
  { value: 'passedUserCount', text: '', align: 'center', width: 100, sort: sortByNumber },
  { value: 'preview', text: '', align: 'center', width: 100, sortable: false },
];

export function useUserUsageTraineeExamTable(props: PropsUserUsageTraineeExamTable) {
  const msgs = useMessages({ prefix: 'report.molecules.userUsageTraineeExamTable' });
  const { getLocation } = useDialogQuery(DialogName.REPORT_USER_USAGE_TRAINEE_EXAM);

  const headers = HEADERS.map((header) => ({
    ...header,
    text: msgs.of(header.value).value,
  }));

  const items = computed(() => {
    return props.examRecords
      .map((e) => ({
        ...e,
        id: `${e.contentId}_${e.times}`,
        passedUserCount: e.passedUserCount === undefined ? '-' : e.passedUserCount,
        correctAnswerRate: formatRate(e.correctAnswerRate),
        toExamDetail: getLocation({
          groupId: props.groupId,
          contentId: e.contentId,
          times: `${e.times}`,
        }),
      }))
      .sort((a, b) => {
        if (a.courseName === b.courseName) {
          if (a.examName === b.examName) {
            if (a.times === b.times) return a.contentId < b.contentId ? -1 : 1;
            return a.times < b.times ? -1 : 1;
          }
          return a.examName < b.examName ? -1 : 1;
        }
        return a.courseName < b.courseName ? -1 : 1;
      });
  });

  return {
    headers,
    items,
    labelTitle: msgs.of('examList'),
  };
}
