







import { defineComponent } from '@vue/composition-api';
import { BarChart } from 'vue-chart-3';

import {
  PropsUserDataAndAverageBarChart,
  useUserDataAndAverageBarChart,
} from './UserDataAndAverageBarChartComposable';

type Props = PropsUserDataAndAverageBarChart;

export default defineComponent({
  name: 'ReportUserDataAndAverageBarChart',
  components: {
    BarChart,
  },
  props: {
    title: { type: String, default: '' },
    userData: { type: Number, default: 0 },
    usersAverage: { type: Number, default: 0 },
    labelData: { type: String, default: '' },
    unit: { type: String, default: '' },
    titleClass: { type: String, default: 'text-caption' },
    labelSize: { type: Number, default: 12 },
  },
  setup(props: Props) {
    return useUserDataAndAverageBarChart(props);
  },
});
