import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';
import { Questionnaire } from '@/base/domains';

const HEADERS: DataTableHeader[] = [
  { value: 'startAndEnd', text: '', class: 'report-questionnaire-table-start-and-end' },
  { value: 'statusLabel', text: '', width: 120 },
  { value: 'title', text: '', class: 'report-questionnaire-table-name' },
  { value: 'createdByName', text: '', class: 'report-questionnaire-table-name' },
  { value: 'expectedRespondentCount', text: '', align: 'center', width: 100 },
  { value: 'respondentCount', text: '', align: 'center', width: 100 },
];

export type QuestionnaireTableItem = Questionnaire & {
  createdByName: string;
};

export type QuestionnaireTableActionPayload = {
  event: string;
  selected: QuestionnaireTableItem[];
};

export type PropsQuestionnaireTable = {
  questionnaires: QuestionnaireTableItem[];
  loading: boolean;
};

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export function useQuestionnaireTable(
  props: PropsQuestionnaireTable,
  emit: (name: string, args: QuestionnaireTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'report.molecules.questionnaireTable' });
  const headers = computed(() => {
    const keys = HEADERS.map((item) => ({
      ...item,
      text: item ? msgs.of(item.value).value : '',
    })) as DataTableHeader[];
    return keys;
  });
  const items = computed(() =>
    props.questionnaires
      .map((item) => {
        const start = item.createdAt.format('YY/MM/DD HH:mm');
        const end = item.finishedAt?.format('MM/DD HH:mm');
        const startAndEnd = end ? [start, end].filter((v) => v).join(' - ') : start.concat(' - ');
        return {
          ...item,
          startAndEnd,
          statusLabel: msgs.of(item.status).value,
          respondentCount: item.respondent.length,
          expectedRespondentCount: item.userIds.length,
        };
      })
      .sort((a, b) => (a.createdAt.isAfter(b.createdAt) ? -1 : 1))
  );

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.questionnaires.find((q) => q.id === id))
      .filter((c) => c) as QuestionnaireTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type QuestionnaireTable = ReturnType<typeof useQuestionnaireTable>;
