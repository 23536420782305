
























import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import QuestionnaireConfirmFinishDialog from '@/base/app/components/molecules/QuestionnaireConfirmFinishDialog.vue';
import QuestionnaireProgress from '@/base/app/components/molecules/QuestionnaireProgress.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';

import { PropsQuestionnaireResult, useQuestionnaireResult } from './QuestionnaireResultComposable';

type Props = PropsQuestionnaireResult;
export default defineComponent({
  name: 'ReportQuestionnaireResult',
  components: {
    DialogAnchorConfirm,
    QuestionnaireProgress,
    QuestionnaireConfirmFinishDialog,
    BaseButton,
  },
  props: {
    id: { type: String, required: true },
    groupId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useQuestionnaireResult(props);
  },
});
