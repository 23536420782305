import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { ExamResult, GroupExam } from '@/base/domains';
import { Optional } from '@/base/types';
import { isSucceeded } from '@/base/usecases';

import {
  GetGroupReportSearchConditionsContent,
  GetGroupReportSearchConditionsUser,
  useGetExamResultsByTrainer,
  useGetGroupReportSearchConditions,
} from '../../../usecases';
import { ExamResultContentSearchFieldsCondition } from '../molecules/ExamResultContentSearchFieldsComposable';
import { ExamResultUserSearchFieldsCondition } from '../molecules/ExamResultUserSearchFieldsComposable';

export type PropsGroupExamResults = {
  groupId: string;
  contentId?: string;
  times?: string;
  topHeight: number;
};

export function useGroupExamResults(props: PropsGroupExamResults) {
  const msgs = useMessages({ prefix: 'report.organisms.groupExamResults' });
  const results = ref<ExamResult[]>([]);
  const loading = ref(false);
  const mode = ref<'content' | 'user'>('content');
  const conditionContent = ref<ExamResultContentSearchFieldsCondition>({ userIds: [] });
  const conditionUser = ref<ExamResultUserSearchFieldsCondition>({});

  function changeMode() {
    conditionContent.value = { userIds: [] };
    conditionUser.value = {};
  }

  const contents = ref<GetGroupReportSearchConditionsContent[]>([]);
  const users = ref<GetGroupReportSearchConditionsUser[]>([]);
  const groupExams = ref<GroupExam[]>([]);

  const getGetConditions = useGetGroupReportSearchConditions();
  async function fetchConditions() {
    loading.value = true;
    const res = await getGetConditions.execute({ groupId: props.groupId });
    if (isSucceeded(res)) {
      contents.value = res.contents;
      users.value = res.users;
      groupExams.value = res.groupExams;
    }
    loading.value = false;
  }

  const getResults = useGetExamResultsByTrainer();
  async function fetchResults() {
    loading.value = true;
    if (mode.value === 'content' && conditionContent.value?.contentId) {
      const res = await getResults.execute({
        groupId: props.groupId,
        contentId: conditionContent.value.contentId,
      });
      if (isSucceeded(res)) results.value = res.examResults;
      else results.value = [];
    } else if (mode.value === 'user' && conditionUser.value?.userId) {
      const res = await getResults.execute({
        groupId: props.groupId,
        userId: conditionUser.value.userId,
      });
      if (isSucceeded(res)) results.value = res.examResults;
      else results.value = [];
    } else {
      results.value = [];
    }
    loading.value = false;
  }

  async function init() {
    await fetchConditions();
    mode.value = 'content';
    conditionContent.value = {
      contentId: props.contentId,
      times: props.times ? parseInt(props.times, 10) : Number.NaN,
      userIds: [],
    };
    conditionUser.value = {};
    fetchResults();
  }
  onMounted(init);
  watch(() => props.groupId, init);

  const display = computed(() => {
    let table = false;
    if (
      (mode.value === 'content' && conditionContent.value?.contentId) ||
      (mode.value === 'user' && conditionUser.value?.userId)
    )
      table = true;
    let contentId: Optional<string>;
    let problem: Optional<number>;
    if (table && mode.value === 'content' && conditionContent.value?.contentId) {
      contentId = conditionContent.value.contentId;
      problem = conditionContent.value.problem;
    } else if (table && mode.value === 'user' && conditionUser.value?.contentId) {
      contentId = conditionUser.value.contentId;
      problem = conditionUser.value.problem;
    }
    let problemSize: Optional<number>;
    if (contentId) {
      const times =
        mode.value === 'user' ? conditionUser.value.times : conditionContent.value.times;
      const counts = results.value
        .filter((r) => r.contentId === contentId && (!times || ('times' in r && r.times === times)))
        .map((r) => r.problemCount);
      if (counts.length > 0) problemSize = Math.max(...counts);
    }
    return { table, problemSize, problem };
  });
  const tableKeys = computed(() => {
    const ret: string[] = [];
    if (mode.value === 'content') ret.push('userName');
    if (mode.value === 'user' && !conditionUser.value.contentId)
      ret.push('courseName', 'contentName');
    if (
      (mode.value === 'content' && !conditionContent.value?.times) ||
      (mode.value === 'user' && !conditionUser.value?.times)
    )
      ret.push('times');
    ret.push('startAndEnd', 'timeLimit', 'level', 'passFail', 'passingStandard', 'score');
    return ret;
  });

  function findContent(id: string) {
    const exam = groupExams.value.find((item) => item.content.id === id);
    return {
      contentName: exam?.content.name || msgs.of('unknownContent', { id }).value,
      courseName: exam?.course.name || msgs.of('unknownCourse').value,
      indexInCourse: exam?.content.indexInCourse ?? 999,
    };
  }

  function findUser(id: string) {
    const user = users.value.find((item) => item.id === id);
    if (!user?.name) return { userName: msgs.of('unknownUser', { id }).value, removed: false };
    if (user.removed)
      return { userName: msgs.of('removedUser', { name: user.name }).value, removed: true };
    return { userName: user.name, removed: false };
  }

  function findGroupExam(id: string) {
    const exam = groupExams.value.find((item) => item.id === id);
    return { times: exam?.times, timeLimit: exam?.timeLimit || '-' };
  }

  const items = computed(() => {
    if (mode.value === 'content') {
      const { times, userIds } = conditionContent.value;
      return results.value
        .filter(
          (item) =>
            (!times || ('times' in item && item.times === times)) &&
            (userIds.length === 0 || userIds.includes(item.userId))
        )
        .map((item) => {
          const groupExam =
            'groupExamId' in item
              ? findGroupExam(item.groupExamId)
              : { times: undefined, timeLimit: '-' };
          return {
            ...item,
            ...findContent(item.contentId),
            ...findUser(item.userId),
            ...groupExam,
          };
        })
        .sort((a, b) => {
          const aTimes = a.times ?? -1;
          const bTimes = b.times ?? -1;
          if (a.userName === b.userName) {
            if (aTimes === bTimes) return a.start.isBefore(b.start) ? -1 : 1;
            return aTimes < bTimes ? -1 : 1;
          }
          if (a.removed === b.removed) return a.userName < b.userName ? -1 : 1;
          return a.removed < b.removed ? -1 : 1;
        });
    }
    const { contentId, times } = conditionUser.value;
    return results.value
      .filter(
        (item) =>
          (!contentId || item.contentId === contentId) &&
          (!times || ('times' in item && item.times === times))
      )
      .map((item) => ({
        ...item,
        ...findContent(item.contentId),
        ...findUser(item.userId),
        times: 'times' in item ? item.times : '-',
      }))
      .sort((a, b) => {
        const aTimes = a.times === '-' ? -1 : a.times;
        const bTimes = b.times === '-' ? -1 : b.times;
        if (a.courseName === b.courseName) {
          if (a.indexInCourse === b.indexInCourse) {
            if (aTimes === bTimes) return a.start.isBefore(b.start) ? -1 : 1;
            return aTimes < bTimes ? -1 : 1;
          }
          return a.indexInCourse - b.indexInCourse;
        }
        return a.courseName < b.courseName ? -1 : 1;
      });
  });

  const { breakpoint } = useVuetify();
  const tableHeight = computed(() => {
    let h = 86;
    if (breakpoint.value?.lgAndUp) h += 46;
    if (breakpoint.value?.md) h += 46 * 2;
    if (breakpoint.value?.smAndDown) h += 46 * 4;
    return `calc(100vh - ${props.topHeight + h}px)`;
  });

  return {
    results: items,
    contents,
    users,
    groupExams,
    loading,
    mode,
    conditionContent,
    conditionUser,
    display,
    tableKeys,
    tableHeight,
    labelExam: msgs.of('exam'),
    labelUserName: msgs.of('userName'),
    labelConditions: msgs.of('conditions'),
    changeMode,
    refresh: fetchResults,
  };
}
