







import { defineComponent } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import UserUsage from '../components/organisms/UserUsage.vue';

export default defineComponent({
  name: 'ReportAdminUserUsagePage',
  components: { MyApp, UserUsage },
  setup() {
    const msgs = useMessages({ prefix: 'report.pages.adminUserUsagePage' });
    return { title: msgs.of('userUsage') };
  },
});
